.ant-dropdown.dropdown-large .ant-dropdown-menu {
  padding: 8px 6px;
}

.ant-dropdown.dropdown-large .ant-dropdown-menu-item {
  font-size: 16px;
  padding: 8px 15px;
}

.ant-dropdown.dropdown-large .ant-dropdown-menu-item .anticon {
  font-size: 18px;
}
