.fuel-tag-links {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.fuel-tag-links .fuel-tag-link {
  padding: 2px 12px;
  background-color: #f1f1f1;
  border: 1px solid #eaeaea;
  color: rgba(0, 0, 0, 0.65) !important;
  font-weight: 500;
  border-radius: 100px;
  word-wrap: normal;
  display: inline-block;
  min-width: 48px;
  text-align: center;
}

@media (max-width: 768px) {
  .fuel-tag-links {
    gap: 10px;
  }
  .fuel-tag-links .fuel-tag-link {
    padding: 10px 15px;
    border-radius: 100px;
  }
}
