.user-messages {
  display: flex;
  flex-direction: column;
  width: 800px;
  padding: 0px;
}

.user-messages .user-messages-header {
  padding: 8px 15px;
  background-color: var(--brand);
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-messages .user-messages-content {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  min-height: 25px;
}
