.fuel-avatar .ant-avatar-string {
  transform: none !important;
  width: 100%;
  left: 0;
  background: #eee;
}
.fuel-avatar .ant-avatar-string img {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  padding: 2px;
}

.fuel-avatar.ant-avatar > img {
  object-fit: contain;
}
