.admin-messages-content {
  display: grid;
  grid-template-rows: 60px minmax(0, 1fr);
}
.user-messages-content .admin-messages-content {
  display: grid;
  grid-template-rows: minmax(0, 1fr);
}

.admin-messages-content .admin-messages-content-header {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  padding: 0px 25px;
  box-shadow: 0px 2px 3px rgb(0 0 0 / 20%);
  z-index: 1;
}
.user-messages-content .admin-messages-content .admin-messages-content-header {
  display: none;
}

.admin-messages-content .admin-messages-content-header .header-context {
  font-weight: 500;
  font-size: 16px;
  user-select: none;
}

.admin-messages-content .admin-messages-content-header .header-more {
  display: flex;
  gap: 2px;
  align-items: flex-end;
}
.admin-messages-content .admin-messages-content-wrapper {
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
}
.user-messages-content .admin-messages-content .admin-messages-content-wrapper {
  display: grid;
  grid-template-rows: minmax(0, 1fr);
}
.admin-messages-content .admin-messages-content-create {
  margin: 25px;
  margin-top: 0px;
  margin-right: 95px;
  border: 2px solid #eee;
  border-radius: 4px;
}
.user-messages-content .admin-messages-content .admin-messages-content-create {
  display: none;
}

.admin-messages-content .admin-messages-content-create .message-editor {
  padding: 10px;
}

.admin-messages-content .admin-messages-content-create .message-editor h1,
.admin-messages-content .admin-messages-content-create .message-editor h2,
.admin-messages-content .admin-messages-content-create .message-editor h3,
.admin-messages-content .admin-messages-content-create .message-editor h4,
.admin-messages-content .admin-messages-content-create .message-editor h5,
.admin-messages-content .admin-messages-content-create .message-editor h6 {
  margin: 0px 0px 10px;
}

.admin-messages-content .admin-messages-content-create .message-editor .placeholder {
  padding: 0px;
  color: rgba(0, 0, 0, 0.45);
  border-bottom: none;
  cursor: text;
  transition: all 0.3s;
}

.admin-messages-content .admin-messages-content-create input {
  border: none;
  padding: 10px;
  width: 100%;
}
.admin-messages-content .admin-messages-content-create input:active,
.admin-messages-content .admin-messages-content-create input:focus {
  border: none;
  outline: none;
}
.admin-messages-content .admin-messages-content-create .create-options {
  padding: 10px;
  background: var(--messages-sidebar);
  border-top: 2px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-messages-content .admin-messages-content-create .how-to-send {
  font-size: 10px;
  padding: 2px 4px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  opacity: 0;
  transition: opacity 200ms;
}

.admin-messages-content .admin-messages-content-create .how-to-send.show-help {
  opacity: 1;
}

.admin-messages-content .admin-messages-content-messages {
  display: flex;
  flex-direction: column-reverse; /* 'column' for start, 'column-reverse' for end */
  overflow-y: auto; /* or overflow-y: auto ... */
  gap: 20px;
  z-index: 0;
  padding: 25px 0px;
}
.admin-messages-content .admin-messages-content-messages .loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.admin-messages-content .admin-messages-content-messages .msg-day {
  border-top: 1px solid #eee;
  position: relative;
  padding: 0px;
}
.admin-messages-content .admin-messages-content-messages .msg-day .msg-day-wrapper {
  position: sticky;
  text-align: center;
  background-color: transparent;
  top: -25px;
  margin-top: -20px;
  padding: 5px 0px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.admin-messages-content .admin-messages-content-messages .msg-day .msg-day-wrapper .msg-day-day {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  padding: 3px 10px;
  border-radius: 100px;
  background-color: #fff;
}
.admin-messages-content .admin-messages-content-messages .msg-day .msg-day-messages {
  display: flex;
  flex-direction: column-reverse;
  z-index: 0;
  padding: 10px 0px 0px;
}

.admin-messages-content .msg-wrapper {
  display: flex;
  gap: 10px;
  padding: 10px 25px;
  transition: background-color 0.2s;
  position: relative;
}

.admin-messages-content .msg-wrapper:hover {
  background-color: rgba(50, 50, 50, 0.05);
}

.admin-messages-content .msg-wrapper .msg-content {
  width: 100%;
}

.admin-messages-content .msg-wrapper .msg-content p {
  margin: 0px;
}

.admin-messages-content .msg-wrapper .msg-content table {
  text-align: left;
  width: 100%;
  border: 1px solid #dadada;
  border-collapse: separate !important;
  border-spacing: 0;
}

.admin-messages-content .msg-wrapper .msg-content table tr {
  background: white;
  border: none;
  border-radius: 5px;
}
.admin-messages-content .msg-wrapper .msg-content table th,
.admin-messages-content .msg-wrapper .msg-content table td {
  padding: 10px 20px;
  border-right: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
}

.admin-messages-content .msg-wrapper .msg-content table th {
  background-color: #efefef;
  font-weight: 500;
}

.admin-messages-content .msg-wrapper .msg-content table th:last-child,
.admin-messages-content .msg-wrapper .msg-content table td:last-child {
  border-right: none;
}

.admin-messages-content .msg-wrapper .msg-content table tr:last-child td {
  border-bottom: none;
}

.admin-messages-content .msg-wrapper:hover .message-options {
  opacity: 1;
}

.admin-messages-content .msg-wrapper .message-options {
  position: absolute;
  right: 95px;
  top: -10px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
}
