.article {
  width: 100%;
}

.article .ant-typography {
  overflow-wrap: anywhere;
}

.article a.ant-typography,
.article .ant-typography a {
  color: rgba(0, 0, 0, 0.85);
}

.article .article-extra a {
  color: #8ab0ab;
}

.article .show-sm {
  display: none;
}

.article .hide-sm {
  display: inline-flex;
}

.article .fuel-news-image .ant-image {
  width: 100%;
}

@media (max-width: 768px) {
  .article .show-sm {
    display: block;
  }

  .article .hide-sm {
    display: none;
  }
}
