.authwall-card {
  padding: 35px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  row-gap: 0px;
  background-color: #fff;
  box-shadow: 0px 2px 4px 1px rgb(0, 0, 0, 0.15);
  max-width: 760px;
  margin: 0px auto;
}

.authwall-card img {
  height: 210px;
}

@media (max-width: 768px) {
  .authwall-card {
    padding: 25px;
    padding-top: 5px;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }
  .authwall-card img {
    height: 150px;
  }
}
