.footer {
  background-color: var(--charcoal-hero);
  padding: 15px;
  z-index: 10;
}

.footer-content {
  color: #fff;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
}

.footer-content .logo .social-links {
  font-size: 20px;
  padding-left: 70px;
}
.footer-content .logo .social-links a {
  color: #fff;
}

.footer-content .made-by {
  display: grid;
  align-items: end;
  justify-content: center;
  color: #fff;
  text-align: center;
}

.footer-content .made-by a {
  color: #fff;
}

.footer-content .made-by .msg {
  font-size: 16px;
}

.footer-content .made-by .tm {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
}

.footer-content .resources {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.footer-content .resources a {
  color: #fff;
}

.footer-content .resources ul {
  list-style-type: none;
  margin: none;
  padding: none;
}

.footer-content .resources ul li {
  padding-bottom: 5px;
  padding-left: 5px;
}
.footer-content .resources ul li:first-child {
  padding-left: 0px;
  font-size: 18px;
}

@media (max-width: 870px) {
  .footer {
    padding: 0px;
  }
  .footer-content {
    grid-template-columns: 1fr;
  }
  .footer-content .logo {
    display: none;
  }
  .footer-content .resources {
    display: none;
  }
}
