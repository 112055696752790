.fuel-modal {
  background-color: #fff;
  display: grid;
  grid-template-columns: 3fr 4fr;
}

.fuel-modal .fuel-modal-msg {
  background-color: var(--charcoal-hero);
  color: #fff;
  padding: 30px;
}

.fuel-modal .fuel-modal-content .ant-modal-content {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

.fuel-modal .fuel-modal-content .ant-modal-content .ant-modal-body .ant-form {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 30px;
  height: 100%;
}

@media (max-width: 825px) {
  .fuel-modal {
    grid-template-columns: 1fr;
  }

  .fuel-modal .fuel-modal-msg {
    display: none;
  }
}
