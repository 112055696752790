@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;600;700&display=swap);
.splash {
  position: relative;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: grid;
  color: #5d5d5d;
}

.splash .ant-spin-dot-item {
  background-color: #00b48a;
}

.google-btn {
  font-family: 'Roboto';
  font-weight: 500 !important;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.54) !important;
}

.google-btn:hover {
  border-color: #d9d9d9 !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.google-btn:active {
  border-color: #d9d9d9 !important;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.google-btn:focus {
  border-color: #d9d9d9 !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

section.fade {
  opacity: 0;
  transition: opacity 800ms ease-out, transform 600ms ease-out;
  will-change: opacity, visibility;
}

section.fade.outro {
  transition: opacity 200ms ease-out, transform 300ms ease-out;
}

/* animate vertically */
section.fade.transform-y-0 {
  transform: translateY(0);
}
section.fade.transform-y-10 {
  transform: translateY(10%);
}
section.fade.transform-y-100 {
  transform: translateY(100%);
}

/* animate horizontally */
section.fade.transform-x-0 {
  transform: translateX(100%);
}
section.fade.transform-x-2 {
  transform: translateX(2%);
}
section.fade.transform-x-10 {
  transform: translateX(10%);
}
section.fade.transform-x-100 {
  transform: translateX(100%);
}

/* delay animation */

section.fade.delay-01 {
  transition-delay: 100ms;
}
section.fade.delay-02 {
  transition-delay: 200ms;
}
section.fade.delay-03 {
  transition-delay: 300ms;
}
section.fade.delay-04 {
  transition-delay: 400ms;
}
section.fade.delay-05 {
  transition-delay: 500ms;
}
section.fade.delay-06 {
  transition-delay: 600ms;
}
section.fade.delay-07 {
  transition-delay: 700ms;
}
section.fade.delay-08 {
  transition-delay: 800ms;
}

section.fade.delay-1 {
  transition-delay: 250ms;
}
section.fade.delay-2 {
  transition-delay: 500ms;
}
section.fade.delay-3 {
  transition-delay: 750ms;
}
section.fade.delay-4 {
  transition-delay: 1000ms;
}
section.fade.delay-5 {
  transition-delay: 1250ms;
}
section.fade.delay-6 {
  transition-delay: 1500ms;
}
section.fade.delay-7 {
  transition-delay: 1750ms;
}
section.fade.delay-8 {
  transition-delay: 2000ms;
}

section.fade.outro {
  transition-delay: 0ms !important;
}

/* just fade in */
section.fade.plain {
  transform: none;
}

section.fade.is-visible {
  opacity: 1;
  transform: none;
}

.ant-form-item-label > label,
.ant-form-item-label > label > .ant-row {
  width: 100%;
}

.authenticontainer {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 1fr;
}

.authenticontainer .marketing {
  max-width: 100%;
  background-color: var(--charcoal-hero);
  display: grid;
  grid-template-rows: 152px 1fr;
  justify-content: end;
  padding: 50px;
}
.authenticontainer .marketing .marketing-message {
  padding-top: 2em;
}

.authenticontainer .user-form {
  width: 100%;
  display: grid;
  grid-template-rows: 152px 1fr 22px;
  padding: 50px;
}

.authenticontainer .user-form .user-content {
  padding: 2em 0px 4em;
}

@media (max-width: 768px) {
  .authenticontainer {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  .authenticontainer .marketing {
    display: none;
  }
  .authenticontainer .user-form {
    padding: 25px;
  }
  .authenticontainer .user-form .user-content {
    padding: 0px 0px 4em;
  }
  .authenticontainer .user-form .marketing-logo {
    margin: 0px auto;
  }
  .authenticontainer .user-form .auth-footer {
    margin: 0px auto;
  }
}

.fuel-tag-links {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 6px;
  gap: 6px;
}

.fuel-tag-links .fuel-tag-link {
  padding: 2px 12px;
  background-color: #f1f1f1;
  border: 1px solid #eaeaea;
  color: rgba(0, 0, 0, 0.65) !important;
  font-weight: 500;
  border-radius: 100px;
  word-wrap: normal;
  display: inline-block;
  min-width: 48px;
  text-align: center;
}

@media (max-width: 768px) {
  .fuel-tag-links {
    grid-gap: 10px;
    gap: 10px;
  }
  .fuel-tag-links .fuel-tag-link {
    padding: 10px 15px;
    border-radius: 100px;
  }
}

.fuel-skeleton {
  display: inline-block;
  min-height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #efefef;
}
.fuel-skeleton::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 1.5s infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.fuel-avatar .ant-avatar-string {
  transform: none !important;
  width: 100%;
  left: 0;
  background: #eee;
}
.fuel-avatar .ant-avatar-string img {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  padding: 2px;
}

.fuel-avatar.ant-avatar > img {
  object-fit: contain;
}

.search-result {
  display: grid;
  width: 100%;
  grid-gap: 2px;
  gap: 2px;
  grid-template-areas:
    'image title'
    'image description';
  grid-template-columns: 26px 1fr;
  padding: 2px 0px;
}

.search-result > .i {
  grid-area: image;
}

.search-result > .t {
  grid-area: title;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.search-result > .d {
  grid-area: description;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  padding-left: 1px;
  color: rgba(0, 0, 0, 0.65);
}

.home.fuel-layout .search-bar.dark input {
  color: #eee;
}

.fuel-modal {
  background-color: #fff;
  display: grid;
  grid-template-columns: 3fr 4fr;
}

.fuel-modal .fuel-modal-msg {
  background-color: var(--charcoal-hero);
  color: #fff;
  padding: 30px;
}

.fuel-modal .fuel-modal-content .ant-modal-content {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

.fuel-modal .fuel-modal-content .ant-modal-content .ant-modal-body .ant-form {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-gap: 30px;
  gap: 30px;
  height: 100%;
}

@media (max-width: 825px) {
  .fuel-modal {
    grid-template-columns: 1fr;
  }

  .fuel-modal .fuel-modal-msg {
    display: none;
  }
}

.list-tags {
  display: flex;
  grid-gap: 2px;
  gap: 2px;
  flex-wrap: wrap;
}

.list-tags .list-tag {
  padding: 2px 6px 2px 12px;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  color: rgba(0, 0, 0, 0.85) !important;
  font-weight: 600;
  font-family: 'Nunito';
  border-radius: 100px;
  word-wrap: normal;
  display: inline-block;
  min-width: 48px;
  text-align: center;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
          user-select: none;
}

.list-tags .list-tag .list-tag-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-tags .list-tag .list-tag-text:hover {
  color: #008c6c;
  cursor: pointer;
}

.list-tags .list-tag .remove-list {
  font-size: 10px;
  display: flex;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
}

.list-tags-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.list-tag-new {
  padding: 2px 12px;
  background-color: #fff;
  border: 1px dashed #e4e4e4;
  color: rgba(0, 0, 0, 0.65) !important;
  font-weight: 500;
  border-radius: 100px;
  word-wrap: normal;
  width: 100px;
  text-align: center;
  font-size: 14px;
  display: flex;
  grid-gap: 6px;
  gap: 6px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.1s, width 0.3s;
  overflow: hidden;
}

.list-tag-new.centered {
  margin: auto;
}

.list-tag-new.active {
  width: 100%;
  text-transform: initial;
  cursor: default;
  justify-content: start;
}

.list-tags .list-tag-new:hover {
  background-color: #fafafa;
}
.list-tags .list-tag-new:active {
  background-color: #fefefe;
}

.stat-section .stat-header {
  text-transform: uppercase;
  font-size: 15px;
  color: #aaa;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
  font-weight: 500;
  margin-bottom: 8px;
  justify-content: space-between;
}
.stat-section .stat-header a {
  color: #aaa;
  transition: color 0.2s;
}

.stat-section .stat-header a:hover {
  color: #888;
}

.grid-stats {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 0;
  gap: 0;
}
.grid-stats .grid-stat {
  border-top: 1px solid #eee;
  padding: 15px;
}
.grid-stats .grid-stat:nth-child(odd) {
  border-right: 1px solid #eee;
}

.grid-stats .grid-stat .grid-stat-value {
  font-size: 20px;
}

.company-gridview .company-summary {
  padding: 15px;
  background-color: #fff;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.company-summary .company-bullets {
  display: grid;
  grid-template-columns: 32px minmax(0, 1fr);
  grid-gap: 15px;
  gap: 15px;
  align-items: start;
  margin-bottom: 15px;
}

.company-summary .company-items {
  display: flex;
  align-items: center;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
}

.company-summary .company-items .company-item {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  font-size: 14px;
}
.w-100 {
  width: 100%;
}

.footer {
  background-color: var(--charcoal-hero);
  padding: 15px;
  z-index: 10;
}

.footer-content {
  color: #fff;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
}

.footer-content .logo .social-links {
  font-size: 20px;
  padding-left: 70px;
}
.footer-content .logo .social-links a {
  color: #fff;
}

.footer-content .made-by {
  display: grid;
  align-items: end;
  justify-content: center;
  color: #fff;
  text-align: center;
}

.footer-content .made-by a {
  color: #fff;
}

.footer-content .made-by .msg {
  font-size: 16px;
}

.footer-content .made-by .tm {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
}

.footer-content .resources {
  display: flex;
  justify-content: flex-end;
  grid-gap: 20px;
  gap: 20px;
}
.footer-content .resources a {
  color: #fff;
}

.footer-content .resources ul {
  list-style-type: none;
  margin: none;
  padding: none;
}

.footer-content .resources ul li {
  padding-bottom: 5px;
  padding-left: 5px;
}
.footer-content .resources ul li:first-child {
  padding-left: 0px;
  font-size: 18px;
}

@media (max-width: 870px) {
  .footer {
    padding: 0px;
  }
  .footer-content {
    grid-template-columns: 1fr;
  }
  .footer-content .logo {
    display: none;
  }
  .footer-content .resources {
    display: none;
  }
}

.user-dropdown {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  height: 100%;
}

.username {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 1200px) {
  .user-dropdown .username {
    display: none;
  }
}

[data-tour-elem='controls'] {
  /* Attribute has this exact value */
  justify-content: center;
}

.fuel-tour {
  max-width: 360px !important;
}
.fuel-tour .link-bold {
  font-weight: 500;
}

.search-result {
  display: grid;
  width: 100%;
  grid-gap: 2px 10px;
  gap: 2px 10px;
  grid-template-areas:
    'image title'
    'image description';
  grid-template-columns: 24px 1fr;
}

.search-result > .i {
  grid-area: image;
}

.search-result > .t {
  grid-area: title;
  font-weight: 400;
  font-size: 18px;
}

.search-result > .d {
  grid-area: description;
  font-weight: 400;
  font-size: 14px;
}

.home.fuel-layout .search-bar.dark input {
  color: #eee;
}

.search-all {
  max-width: calc(100vw - 10px);
}

.search-all .loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-all .loader-option {
  position: absolute;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
}

.search-all .ant-select-item.ant-select-item-group {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  cursor: default;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin: 10px;
  padding: 0px;
}

.search-all .noresult-option {
  align-items: center;
}

div[data-id='page-header-search'] .ant-select-selector .ant-select-selection-search {
  color: white;
}
div[data-id='page-header-search'] .ant-select-selector .ant-select-selection-search input {
  font-size: 16px;
}
div[data-id='page-header-search'] .ant-select-selector .ant-select-selection-placeholder {
  color: #bfbfbf;
  font-size: 16px;
}

.advancedSearchTiles-wrapper {
  display: flex;
  justify-content: space-between;
}

.advancedSearchTiles-wrapper .filter-link {
  font-size: 16px;
}

@media (max-width: 768px) {
  .search-all-input {
    display: none !important;
  }
}

.ant-list-header {
  margin-bottom: 10px;
}
.ant-list-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.7);
}

.company-quick svg {
  vertical-align: middle;
}
.company-quick .line-row {
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  overflow-wrap: anywhere;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}

:root {
  --messages-sidebar: #f7fdfb;
  --messages-nav-group: #e3f7f1;
  --messages-nav-header: #00b48a;
}

.admin-messages-wrapper {
  display: grid;
  grid-template-columns: 300px minmax(0, 1fr);
  height: 100%;
}

.user-messages-content .admin-messages-wrapper {
  grid-template-columns: 225px minmax(0, 1fr);
  grid-auto-rows: 500px;
  height: 500px;
}

.admin-messages-wrapper .admin-messages-sidebar {
  overflow-y: auto;
  background-color: var(--messages-sidebar);
}
.admin-messages-wrapper .admin-messages-content {
  height: calc(100vh - 64px);
}
.user-messages-content .admin-messages-wrapper .admin-messages-content {
  height: auto;
}

.admin-messages-sidebar .messages-header {
  background-color: var(--messages-nav-header);
  padding: 15px 20px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-bottom: inset 2px rgba(0, 0, 0, 0.1);
}

.user-messages-content .messages-header {
  display: none;
}

.admin-messages-sidebar .messages-nav {
  max-height: calc(100vh - 167px);
  overflow-y: auto;
}

.admin-messages-sidebar .messages-nav .messages-nav-group .messages-nav-header {
  background-color: var(--messages-nav-group);
  color: rgba(0, 0, 0, 0.95);
  font-size: 16px;
  font-weight: 500;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.admin-messages-sidebar .messages-nav .messages-nav-group .messages-nav-items {
  padding: 10px 0px 15px;
  display: block;
}
.admin-messages-sidebar .messages-nav .messages-nav-group .messages-nav-items.hidden {
  display: none;
}
.admin-messages-sidebar .messages-nav .messages-nav-group .messages-nav-items .messages-nav-item {
  color: rgba(0, 0, 0, 0.95);
  font-size: 16px;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: 100%;
}
.admin-messages-sidebar
  .messages-nav
  .messages-nav-group
  .messages-nav-items
  .messages-nav-item
  .messages-nav-item-wrap {
  padding: 8px 20px;
  width: 100%;
}

.admin-messages-sidebar .channel-search {
  color: #fff;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.admin-messages-sidebar .channel-search::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.admin-messages-sidebar .messages-nav .messages-nav-group .messages-nav-items .messages-nav-item:before {
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  position: absolute;
  width: 0px;
  background-color: var(--messages-nav-header);
  transition: width 0.1s;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.admin-messages-sidebar .messages-nav .messages-nav-group .messages-nav-items .messages-nav-item.active:before,
.admin-messages-sidebar .messages-nav .messages-nav-group .messages-nav-items .messages-nav-item:focus:before {
  position: absolute;
  width: 5px;
}
.admin-messages-sidebar
  .messages-nav
  .messages-nav-group
  .messages-nav-items
  .messages-nav-item:hover:not(.active)
  .messages-nav-text {
  font-weight: 500;
}
.admin-messages-sidebar .messages-nav .messages-nav-group .messages-nav-items .messages-nav-item.active,
.admin-messages-sidebar .messages-nav .messages-nav-group .messages-nav-items .messages-nav-item:focus {
  background-color: #02daa72b;
}

.admin-messages-sidebar .messages-nav .messages-nav-group .messages-nav-items .messages-nav-item:active:before {
  width: 7px;
}

.admin-messages-sidebar .messages-nav .messages-nav-text {
  position: relative;
  -webkit-user-select: none;
          user-select: none;
}

@keyframes ping {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.ping::before,
.ping::after {
  content: '';
  position: absolute;
  top: 40%;
  right: 20px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--accent);
}

.ping::before {
  animation: ping 1.7s ease infinite;
  opacity: 0.25;
}

.empty-state {
  min-height: 150px;
  width: 100%;
  background-color: #fcfcfc;
  border-radius: 4px;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
}

.empty-data-wrapper {
  width: 100%;
  min-width: 200px;
  height: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.empty-img {
  width: 150px;
  height: 150px;
}
.empty-img img {
  width: 100%;
  height: 100%;
}
.empty-text {
  color: #6b6b6b;
  font-size: 18px;
  line-height: 175%;
}

.admin-messages-content {
  display: grid;
  grid-template-rows: 60px minmax(0, 1fr);
}
.user-messages-content .admin-messages-content {
  display: grid;
  grid-template-rows: minmax(0, 1fr);
}

.admin-messages-content .admin-messages-content-header {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  padding: 0px 25px;
  box-shadow: 0px 2px 3px rgb(0 0 0 / 20%);
  z-index: 1;
}
.user-messages-content .admin-messages-content .admin-messages-content-header {
  display: none;
}

.admin-messages-content .admin-messages-content-header .header-context {
  font-weight: 500;
  font-size: 16px;
  -webkit-user-select: none;
          user-select: none;
}

.admin-messages-content .admin-messages-content-header .header-more {
  display: flex;
  grid-gap: 2px;
  gap: 2px;
  align-items: flex-end;
}
.admin-messages-content .admin-messages-content-wrapper {
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
}
.user-messages-content .admin-messages-content .admin-messages-content-wrapper {
  display: grid;
  grid-template-rows: minmax(0, 1fr);
}
.admin-messages-content .admin-messages-content-create {
  margin: 25px;
  margin-top: 0px;
  margin-right: 95px;
  border: 2px solid #eee;
  border-radius: 4px;
}
.user-messages-content .admin-messages-content .admin-messages-content-create {
  display: none;
}

.admin-messages-content .admin-messages-content-create .message-editor {
  padding: 10px;
}

.admin-messages-content .admin-messages-content-create .message-editor h1,
.admin-messages-content .admin-messages-content-create .message-editor h2,
.admin-messages-content .admin-messages-content-create .message-editor h3,
.admin-messages-content .admin-messages-content-create .message-editor h4,
.admin-messages-content .admin-messages-content-create .message-editor h5,
.admin-messages-content .admin-messages-content-create .message-editor h6 {
  margin: 0px 0px 10px;
}

.admin-messages-content .admin-messages-content-create .message-editor .placeholder {
  padding: 0px;
  color: rgba(0, 0, 0, 0.45);
  border-bottom: none;
  cursor: text;
  transition: all 0.3s;
}

.admin-messages-content .admin-messages-content-create input {
  border: none;
  padding: 10px;
  width: 100%;
}
.admin-messages-content .admin-messages-content-create input:active,
.admin-messages-content .admin-messages-content-create input:focus {
  border: none;
  outline: none;
}
.admin-messages-content .admin-messages-content-create .create-options {
  padding: 10px;
  background: var(--messages-sidebar);
  border-top: 2px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-messages-content .admin-messages-content-create .how-to-send {
  font-size: 10px;
  padding: 2px 4px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  opacity: 0;
  transition: opacity 200ms;
}

.admin-messages-content .admin-messages-content-create .how-to-send.show-help {
  opacity: 1;
}

.admin-messages-content .admin-messages-content-messages {
  display: flex;
  flex-direction: column-reverse; /* 'column' for start, 'column-reverse' for end */
  overflow-y: auto; /* or overflow-y: auto ... */
  grid-gap: 20px;
  gap: 20px;
  z-index: 0;
  padding: 25px 0px;
}
.admin-messages-content .admin-messages-content-messages .loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.admin-messages-content .admin-messages-content-messages .msg-day {
  border-top: 1px solid #eee;
  position: relative;
  padding: 0px;
}
.admin-messages-content .admin-messages-content-messages .msg-day .msg-day-wrapper {
  position: -webkit-sticky;
  position: sticky;
  text-align: center;
  background-color: transparent;
  top: -25px;
  margin-top: -20px;
  padding: 5px 0px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.admin-messages-content .admin-messages-content-messages .msg-day .msg-day-wrapper .msg-day-day {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  padding: 3px 10px;
  border-radius: 100px;
  background-color: #fff;
}
.admin-messages-content .admin-messages-content-messages .msg-day .msg-day-messages {
  display: flex;
  flex-direction: column-reverse;
  z-index: 0;
  padding: 10px 0px 0px;
}

.admin-messages-content .msg-wrapper {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px 25px;
  transition: background-color 0.2s;
  position: relative;
}

.admin-messages-content .msg-wrapper:hover {
  background-color: rgba(50, 50, 50, 0.05);
}

.admin-messages-content .msg-wrapper .msg-content {
  width: 100%;
}

.admin-messages-content .msg-wrapper .msg-content p {
  margin: 0px;
}

.admin-messages-content .msg-wrapper .msg-content table {
  text-align: left;
  width: 100%;
  border: 1px solid #dadada;
  border-collapse: separate !important;
  border-spacing: 0;
}

.admin-messages-content .msg-wrapper .msg-content table tr {
  background: white;
  border: none;
  border-radius: 5px;
}
.admin-messages-content .msg-wrapper .msg-content table th,
.admin-messages-content .msg-wrapper .msg-content table td {
  padding: 10px 20px;
  border-right: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
}

.admin-messages-content .msg-wrapper .msg-content table th {
  background-color: #efefef;
  font-weight: 500;
}

.admin-messages-content .msg-wrapper .msg-content table th:last-child,
.admin-messages-content .msg-wrapper .msg-content table td:last-child {
  border-right: none;
}

.admin-messages-content .msg-wrapper .msg-content table tr:last-child td {
  border-bottom: none;
}

.admin-messages-content .msg-wrapper:hover .message-options {
  opacity: 1;
}

.admin-messages-content .msg-wrapper .message-options {
  position: absolute;
  right: 95px;
  top: -10px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
}

.user-messages {
  display: flex;
  flex-direction: column;
  width: 800px;
  padding: 0px;
}

.user-messages .user-messages-header {
  padding: 8px 15px;
  background-color: var(--brand);
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-messages .user-messages-content {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  min-height: 25px;
}

.home.fuel-layout {
  display: grid;
  grid-template-rows: 64px 1fr auto;
  min-height: 100vh;
  background-color: #fff;
  /* PAGE HEADER */
  /*NOTEBOOKS*/
  /* RESPONSIVE */
  /* RESPONSIVE */
}
.home.fuel-layout .card-bg {
  background-color: #fcfcfc;
  border-radius: 4px;
  padding: 30px;
}
.home.fuel-layout .d-flex {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  justify-content: center;
}
.home.fuel-layout .d-flex-vertical {
  flex-direction: column;
}
.home.fuel-layout .d-flex-horizontal {
  flex-direction: row;
}
.home.fuel-layout .ant-page-header.has-breadcrumb {
  padding-top: 0px;
}
.home.fuel-layout .ant-page-header {
  padding: 16px 0px;
}
.home.fuel-layout .ant-breadcrumb > span:last-child {
  color: var(--brand);
  font-weight: 500;
}
.home.fuel-layout .ant-table.ant-table-middle .ant-table-tbody > tr > td.cell-avatars {
  padding: 7px 8px 0px;
}
.home.fuel-layout .ant-list-bordered {
  border: 1px solid #f0f0f0;
}
.home.fuel-layout .tilt {
  position: relative;
}
.home.fuel-layout .tilt:after {
  background: inherit;
  bottom: 0;
  content: '';
  display: block;
  height: 20%;
  left: 0;
  position: absolute;
  right: 0;
  transform: skewY(1.5deg);
  transform-origin: 0 100%;
  z-index: -1;
}
.home.fuel-layout .tilt.flip {
  transform: skewY(-1.5deg);
  transform-origin: 100%;
}
.home.fuel-layout .layout-padding {
  padding: 25px 50px;
}
.home.fuel-layout .layout-padding-none {
  padding: 0px 50px;
}
.home.fuel-layout .layout-max-width {
  max-width: 1400px;
  margin: auto;
}
.home.fuel-layout .fuel-sider {
  display: none;
}
.home.fuel-layout .trigger {
  display: none;
  color: #fff;
  align-items: center;
  height: 100%;
}
.home.fuel-layout .fuel-header,
.home.fuel-layout .ant-menu.ant-menu-dark {
  background-color: var(--charcoal-header);
}
.home.fuel-layout .fuel-header .header-wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: 210px 3fr 1fr auto;
  grid-template-rows: 64px;
  grid-gap: 20px;
  gap: 20px;
}
.home.fuel-layout .fuel-header .full-width-search {
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 36px 1fr;
  grid-gap: 20px;
  gap: 20px;
}
.home.fuel-layout .search-btn {
  display: none;
}
.home.fuel-layout .fuel-content {
  background-color: #fff;
  padding-bottom: 100px;
}
.home.fuel-layout h1.ant-typography,
.home.fuel-layout h2.ant-typography,
.home.fuel-layout h3.ant-typography,
.home.fuel-layout h4.ant-typography,
.home.fuel-layout h5.ant-typography,
.home.fuel-layout h6.ant-typography {
  font-weight: 500;
}
.home.fuel-layout .fuel-page-header .ant-page-header-heading-title {
  font-weight: 400;
  font-size: 26px;
  white-space: initial;
}
.home.fuel-layout .fuel-page {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: auto 1fr;
  grid-template-areas: 'bc bc bc bc bc bc bc bc bc' 'in in co co co co co co ex';
  grid-gap: 30px;
  gap: 30px;
}
.home.fuel-layout .fuel-page .page-grid {
  min-width: 0;
}
.home.fuel-layout .fuel-page .page-breadcrumb {
  grid-area: bc;
}
.home.fuel-layout .fuel-page .page-info {
  grid-area: in;
}
.home.fuel-layout .fuel-page .page-content {
  grid-area: co;
}
.home.fuel-layout .fuel-page .page-extra {
  grid-area: ex;
}
.home.fuel-layout .fuel-page .header-shadow {
  position: absolute;
  height: 114px;
  width: 100%;
  left: 0;
  border-bottom: 1px solid #efefef;
  margin-top: -25px;
}
.home.fuel-layout .fuel-page .ant-typography-edit-content {
  left: 0px;
}
.home.fuel-layout .fuel-menu.ant-menu-dark .ant-menu-item,
.home.fuel-layout .fuel-menu.ant-menu-dark .ant-menu-item a,
.home.fuel-layout .fuel-menu.ant-menu-dark .ant-menu-submenu,
.home.fuel-layout .fuel-menu.ant-menu-dark .ant-menu-submenu a,
.home.fuel-layout .fuel-menu.ant-menu-dark .ant-menu-submenu .ant-menu-item,
.home.fuel-layout .fuel-menu.ant-menu-dark .ant-menu-submenu .ant-menu-item a {
  color: rgba(255, 255, 255, 0.85);
  font-weight: 600;
  font-size: 13px;
  display: flex;
}
.home.fuel-layout .fuel-menu.ant-menu-dark .ant-menu-item-selected,
.home.fuel-layout .fuel-menu.ant-menu-dark .ant-menu-item:hover,
.home.fuel-layout .fuel-menu.ant-menu-dark .ant-menu-item:hover a {
  background-color: transparent;
  color: #fff;
}
@media (max-width: 1200px) {
  .home.fuel-layout .fuel-page {
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: auto 1fr;
    grid-template-areas: 'bc bc bc bc bc bc bc bc bc' 'co co co co co co co ex ex';
  }
  .home.fuel-layout .fuel-page .page-info {
    display: none;
  }
  .home.fuel-layout .fuel-header .header-wrapper {
    grid-template-columns: 56px 1fr 1fr 120px;
  }
  .home.fuel-layout .fuel-header .header-wrapper .fuel-logo {
    object-fit: cover;
    object-position: 0 0;
    width: 55px;
  }
  .home.fuel-layout .mobile-hide-w1200 {
    display: none;
  }
}
@media (max-width: 880px) {
  .home.fuel-layout .fuel-header {
    padding: 0px 20px;
  }
  .home.fuel-layout .fuel-header .header-wrapper {
    grid-template-columns: 56px 200px 1fr auto;
    grid-gap: 10px;
    gap: 10px;
  }
  .home.fuel-layout .search-all {
    display: none;
  }
  .home.fuel-layout .fuel-menu {
    display: none;
  }
  .home.fuel-layout .trigger {
    display: flex;
  }
  .home.fuel-layout .fuel-sider {
    display: block;
  }
}
@media (max-width: 992px) {
  .home.fuel-layout .mobile-hide-w992 {
    display: none;
  }
}
@media (max-width: 768px) {
  .home.fuel-layout .layout-padding {
    padding: 25px;
  }
  .home.fuel-layout .layout-padding-none {
    /*need padding for mobile*/
    padding: 25px;
  }
  .home.fuel-layout .fuel-header .header-wrapper {
    grid-template-columns: 56px 1fr auto auto;
    grid-gap: 10px;
    gap: 10px;
  }
  .home.fuel-layout .fuel-page {
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: auto 1fr;
    grid-template-areas: 'bc bc bc bc bc bc bc bc bc' 'co co co co co co co co co';
  }
  .home.fuel-layout .fuel-page .page-info {
    display: none;
  }
  .home.fuel-layout .fuel-page .page-extra {
    display: none;
  }
  .home.fuel-layout .search-btn {
    display: block;
  }
  .home.fuel-layout .mobile-hide-w768 {
    display: none;
  }
}
@media (max-width: 576px) {
  .home.fuel-layout .mobile-hide-w576 {
    display: none;
  }
}
.home.fuel-layout.no-footer {
  grid-template-rows: 64px 1fr;
}
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: var(--charcoal-two) !important;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item a {
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
  font-size: 16px;
}
.ant-popover.remove-padding .ant-popover-inner-content {
  padding: 0px;
}
@media print {
  .fuel-page {
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: 'co co co co co co co co co';
  }
  .fuel-page .page-breadcrumb .ant-breadcrumb,
  .fuel-page .page-breadcrumb .ant-page-header-heading-extra,
  .fuel-page .page-breadcrumb .header-shadow {
    display: none;
  }
  .fuel-page .page-info {
    display: none;
  }
  .fuel-page .page-extra {
    display: none;
  }
}
.fuel-card {
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  padding: 30px;
}
/** DEPRECATED, working to remove **/
.fuel-body .layout2 {
  /* ACCENT BUTTON */
  /* SIDER */
  /* TODO: ORGANIZE */
  /*NOTEBOOKS*/
  /* RESPONSIVE */
}
.fuel-body .layout2 code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.fuel-body .layout2 #root {
  height: 100%;
}
.fuel-body .layout2 .ant-table.ant-table-middle .ant-table-tbody > tr > td.cell-avatars {
  padding: 7px 8px 0px;
}
.fuel-body .layout2 .ant-btn-accent {
  background: var(--yellow);
  border-color: var(--yellow);
}
.fuel-body .layout2 .ant-layout-header {
  background: #fff;
}
.fuel-body .layout2 .fuel-sider.ant-layout-sider {
  background: var(--charcoal-header);
  transition: none;
}
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu {
  background: transparent;
  color: #ccc;
  font-weight: 400;
  font-size: 16px;
}
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu > .ant-menu-item-divider {
  background-color: rgba(255, 255, 255, 0.15);
  margin: 15px 0px;
}
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu .ant-menu-item.ant-menu-item-selected {
  background: var(--brand);
  color: #fff;
  border-radius: 3px;
}
.fuel-body .layout2 .fuel-sider.ant-layout-sider.ant-layout-sider-collapsed .ant-menu .ant-menu-item.ant-menu-item-selected {
  border-radius: 0px;
}
.fuel-body .layout2 .fuel-sider .ant-menu-item a,
.fuel-body .layout2 .fuel-sider .ant-menu-submenu a {
  color: #ccc;
}
.fuel-body .layout2 .fuel-sider .ant-menu-item a:hover,
.fuel-body .layout2 .fuel-sider .ant-menu-submenu a:hover {
  color: var(--brand);
}
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu .ant-menu-item-selected a {
  color: #fff;
}
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu .ant-menu-item-selected a:hover {
  color: #fff;
}
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu ul,
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu ol {
  padding: 0px 10px;
}
.fuel-body .layout2 .fuel-sider.ant-layout-sider.ant-layout-sider-collapsed .ant-menu ul,
.fuel-body .layout2 .fuel-sider.ant-layout-sider.ant-layout-sider-collapsed .ant-menu ol {
  padding: 0px;
}
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu-item-group-title {
  color: rgba(255, 255, 255, 0.45);
}
.fuel-body .layout2 .fuel-sider.ant-layout-sider.ant-layout-sider-collapsed .ant-menu-item-group-title {
  color: transparent;
}
.fuel-body .layout2 .fuel-sider.ant-layout-sider.ant-layout-sider-collapsed .hide-collapsed {
  display: none;
}
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu-inline .ant-menu-item,
.fuel-body .layout2 .ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu .ant-menu-item:not(.ant-menu-item-selected):hover,
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu-item-active,
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu-submenu-active,
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu-submenu-title:hover {
  color: var(--brand);
}
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu-vertical .ant-menu-item::after,
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu-vertical-left .ant-menu-item::after,
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu-vertical-right .ant-menu-item::after,
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu-inline .ant-menu-item::after {
  left: 0px;
  right: auto;
  border-right: none;
}
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu-inline,
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu-vertical,
.fuel-body .layout2 .fuel-sider.ant-layout-sider .ant-menu-vertical-left {
  border-right: none;
}
.fuel-body .layout2 .fuel-sider .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.fuel-body .layout2 .fuel-sider .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-image: linear-gradient(to right, #cccccc, #cccccc);
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.fuel-body .layout2 .fuel-sider .ant-menu-submenu-inline.ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.fuel-body .layout2 .fuel-sider .ant-menu-submenu-inline.ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background-image: linear-gradient(to right, var(--brand), var(--brand));
}
.fuel-body .layout2 .recently-viewed .ant-menu-item-group-list .ant-menu-item {
  align-items: center;
  display: flex;
  height: 32px;
}
.fuel-body .layout2 .recently-viewed .ant-menu-item-group-list .ant-menu-item img {
  margin-right: 8px;
}
.fuel-body .layout2 .search .ant-input-prefix {
  margin-right: 12px;
}
.fuel-body .layout2 .ant-table-thead > tr > th {
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
}
.fuel-body .layout2 .ant-btn-text {
  color: rgba(0, 0, 0, 0.65);
  background: 0 0;
  border-color: transparent;
  box-shadow: none;
}
.fuel-body .layout2 .ant-btn-text:hover,
.fuel-body .layout2 .ant-btn-text:focus {
  color: rgba(0, 0, 0, 0.65);
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.fuel-body .layout2 .ant-drawer-body {
  padding: 0px;
  padding-right: 1px;
}
.fuel-body .layout2 h1.ant-typography,
.fuel-body .layout2 h2.ant-typography,
.fuel-body .layout2 h3.ant-typography,
.fuel-body .layout2 h4.ant-typography,
.fuel-body .layout2 h5.ant-typography,
.fuel-body .layout2 h6.ant-typography {
  font-weight: 400;
}
.fuel-body .layout2 #notebook-content,
.fuel-body .layout2 .limit-width {
  max-width: 1080px;
  margin: 30px auto;
  min-height: 100%;
  display: grid;
  grid-template-rows: -webkit-min-content;
  grid-template-rows: min-content;
  word-break: break-word;
}
.fuel-body .layout2 #notebook-content {
  font-size: 16px;
}
.fuel-body .layout2 .card-bg {
  background-color: var(--card-bg);
  padding: 30px;
}
@media (min-width: 768px) {
  .fuel-body .layout2 .fuel-header {
    padding: 0 50px;
  }
}
.recently-viewed .ant-menu-item-group-list .ant-menu-item img {
  margin-right: 8px;
}
/*other drawers*/
.ant-drawer-content-wrapper {
  max-width: 100%;
}
/* DRAWER */
.fuel-sider .ant-drawer-header {
  background: var(--charcoal-header);
  transition: none;
  padding: 0px;
  border-bottom: none;
  border-radius: 0px;
}
.fuel-sider .ant-drawer-header .logo {
  width: 100%;
  height: 92px;
  padding: 0px 18px;
  float: left;
  display: flex;
}
.fuel-sider .ant-drawer-header .logo div {
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
}
.fuel-sider .ant-drawer-body {
  background: var(--charcoal-header);
  transition: none;
  padding: 0px;
}
.fuel-sider .ant-menu {
  background: transparent;
  color: #ccc;
  font-weight: 400;
  font-size: 16px;
}
.fuel-sider .ant-menu > .ant-menu-item-divider {
  padding: 0px 10px;
  background-color: rgba(255, 255, 255, 0.45);
}
.fuel-sider .ant-menu .ant-menu-item {
  border-radius: 3px;
}
.fuel-sider .ant-menu .ant-menu-item.ant-menu-item-selected {
  background: var(--brand);
  color: #fff;
  border-radius: 3px;
}
.recent-activity .ant-menu-item-group-list .ant-menu-item img {
  margin-right: 8px;
  border-radius: 4px;
}
.recent-activity .ant-menu-item-group-list .ant-menu-item a {
  align-items: center;
  display: flex;
}
.fuel-sider .ant-menu-item a,
.fuel-sider .ant-menu-submenu a {
  color: #ccc;
}
.fuel-sider .ant-menu-item a:hover,
.fuel-sider .ant-menu-submenu a:hover {
  color: var(--brand);
}
.fuel-sider .ant-menu .ant-menu-item-selected a {
  color: #fff;
}
.fuel-sider .ant-menu .ant-menu-item-selected a:hover {
  color: #fff;
}
.fuel-sider .ant-menu ul,
.fuel-sider .ant-menu ol {
  padding: 0px 10px;
}
.fuel-sider .ant-menu-item-group-title {
  color: rgba(255, 255, 255, 0.45);
}
.fuel-sider .ant-menu-inline .ant-menu-item {
  width: 100%;
}
.fuel-sider .ant-menu .ant-menu-item:not(.ant-menu-item-selected):hover,
.fuel-sider .ant-menu-item-active,
.fuel-sider .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.fuel-sider .ant-menu-submenu-active,
.fuel-sider .ant-menu-submenu-title:hover {
  color: var(--brand);
}
.fuel-sider .ant-menu-vertical .ant-menu-item::after,
.fuel-sider .ant-menu-vertical-left .ant-menu-item::after,
.fuel-sider .ant-menu-vertical-right .ant-menu-item::after,
.fuel-sider .ant-menu-inline .ant-menu-item::after {
  left: 0px;
  right: auto;
  border-right: none;
}
.fuel-sider .ant-menu-inline,
.fuel-sider .ant-menu-vertical,
.fuel-sider .ant-menu-vertical-left {
  border-right: none;
}
.fuel-sider .ant-tag {
  margin-bottom: 8px;
}
.fuel-sider .recent-activity .ant-menu-item {
  padding-left: 8px !important;
  margin: 0px;
}
.fuel-sider.ant-layout-sider .ant-menu .recent-activity .ant-menu-item.ant-menu-item-selected {
  background: transparent;
  color: #ccc;
}
.fuel-sider .ant-menu-submenu > .ant-menu {
  background-color: transparent;
}
.fuel-sider .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.fuel-sider .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-image: linear-gradient(to right, #cccccc, #cccccc);
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.fuel-sider .anticon {
  font-size: 18px !important;
}
.fuel-sider:not(.ant-layout-sider-collapsed) .home-nav {
  width: 92% !important;
  margin: 8px auto;
  padding-left: 14px !important;
}
/* UTILITY */
.ant-modal-root .text-muted,
.text-muted {
  color: #6c757d !important;
  margin-bottom: 5px !important;
}
.ant-modal-root .mute-title,
.mute-title {
  color: rgba(0, 0, 0, 0.85);
}
.ant-modal-root .weight-400,
.weight-400 {
  font-weight: 400;
}
.ant-modal-root h1.ant-typography,
.ant-modal-root h2.ant-typography,
.ant-modal-root h3.ant-typography,
.ant-modal-root h4.ant-typography,
.ant-modal-root h5.ant-typography,
.ant-modal-root h6.ant-typography {
  font-weight: 400;
}
.no-margin,
.ant-modal-root .no-margin {
  margin: 0px;
}
.fuel-header-nav .nav-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.fuel-header-nav .nav-list .nav-item {
  position: relative;
  padding: 0 16px;
}
.fuel-header-nav .nav-list .nav-item > a,
.fuel-header-nav .nav-list .nav-item > span {
  line-height: 64px;
}
.fuel-header-nav .nav-list .nav-item > a,
.fuel-header-nav .nav-list .nav-item > span {
  display: block;
  font-weight: 600;
  font-size: 15px;
  color: hsla(0, 0%, 100%, 0.85);
  text-decoration: none;
}
.fuel-header-nav .nav-list .dropdown,
.fuel-header-nav .nav-list .nested-dropdown {
  position: absolute;
  top: 110%;
  z-index: 1000000;
  background-color: transparent;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease 0s, visibility 0s linear 0s, top 0.2s ease 0s;
  min-width: 180px;
}
.fuel-header-nav .nav-list .nested-dropdown {
  top: 10px;
  left: 100%;
  transition: none;
}
.fuel-header-nav .nav-list .dropdown .dropdown-wrap,
.fuel-header-nav .nav-list .nested-dropdown .nested-dropdown-wrap {
  position: relative;
  padding: 10px;
  line-height: 20px;
  background-color: #fff;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-top: -10px;
}
.fuel-header-nav .nav-list .dropdown .dropdown-pointer {
  position: absolute;
  left: 24px;
  top: -8px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top: 0;
  border-bottom: 8px solid #fff;
}
.fuel-header-nav .nav-list .dropdown .dropdown-item.has-children {
  position: relative;
}
.fuel-header-nav .nav-list .dropdown .dropdown-item,
.fuel-header-nav .nav-list .dropdown .dropdown-item .nested-dropdown-item {
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-gap: 15px;
  gap: 15px;
  cursor: pointer;
  align-items: flex-start;
  border-radius: 4px;
  padding: 10px;
}
.fuel-header-nav .nav-list .dropdown .dropdown-item:last-child {
  margin-bottom: 0;
}
.fuel-header-nav .nav-list .dropdown .dropdown-item:hover,
.fuel-header-nav .nav-list .nested-dropdown .nested-dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.fuel-header-nav .nav-list .dropdown .dropdown-item .dropdown-text div,
.fuel-header-nav .nav-list .nested-dropdown .nested-dropdown-item {
  margin: 0;
  margin-bottom: 3px;
  line-height: 20px;
  font-size: 16px;
  color: #333 !important;
  font-weight: 500;
}
.fuel-header-nav .nav-list .dropdown .dropdown-item .dropdown-icon {
  padding: 5px;
  border-radius: 5px;
  color: #008c6c;
  font-size: 18px;
  position: relative;
  text-align: center;
  border: 1px solid var(--messages-nav-group);
}
.fuel-header-nav .nav-list .dropdown.advisory-key .dropdown-item .dropdown-icon {
  color: #00668c;
}
.fuel-header-nav .nav-list .dropdown .dropdown-item .dropdown-icon .blob,
.fuel-header-nav .nav-list .dropdown .dropdown-item .dropdown-icon .blob::after,
.fuel-header-nav .nav-list .dropdown .dropdown-item .dropdown-icon .blob::before {
  width: 50px;
  height: 50px;
  border-radius: 25% 75% 22% 78% / 62% 50% 50% 38%;
}
.fuel-header-nav .nav-list .dropdown.advisory-key .dropdown-item .dropdown-icon .blob,
.fuel-header-nav .nav-list .dropdown.advisory-key .dropdown-item .dropdown-icon .blob::after,
.fuel-header-nav .nav-list .dropdown.advisory-key .dropdown-item .dropdown-icon .blob::before {
  width: 50px;
  height: 50px;
  border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
}
.fuel-header-nav .nav-list .dropdown .dropdown-item .dropdown-icon .blob {
  position: absolute;
  background-color: #d2e6e0;
  isolation: isolate;
  display: none;
}
.fuel-header-nav .nav-list .dropdown.advisory-key .dropdown-item .dropdown-icon .blob {
  background-color: #e5eff3;
}
.fuel-header-nav .nav-list .dropdown .dropdown-item .dropdown-icon .blob::before {
  top: 0%;
  left: -25%;
}
.fuel-header-nav .nav-list .dropdown .dropdown-item .dropdown-icon .blob::after {
  top: 25%;
  left: -25%;
}
.fuel-header-nav .nav-list .dropdown .dropdown-item .dropdown-text p {
  margin: 0;
  font-size: 14px;
  color: #666;
}
.fuel-header-nav .nav-list .nav-item > a:hover + .dropdown,
.fuel-header-nav .nav-list .nav-item > span:hover + .dropdown,
.fuel-header-nav .nav-list .dropdown:hover {
  opacity: 1;
  visibility: visible;
  top: 100%;
  transition-delay: 0s, 0s, 0s;
}
.fuel-header-nav .nav-list .dropdown-item:hover > .nested-dropdown,
.fuel-header-nav .nav-list .dropdown-item:hover > .nested-dropdown,
.fuel-header-nav .nav-list .nested-dropdown:hover {
  opacity: 1;
  visibility: visible;
}
.fuel-header-nav .nav-list .dropdown.dir_left {
  right: 0;
}
.fuel-header-nav .nav-list .dropdown.dir_right {
  left: 0;
}
.dropdown-wrap.strategy {
  display: grid;
  grid-template-columns: 580px auto;
  grid-gap: 10px;
  gap: 10px;
  padding: 0 !important;
}
.dropdown-wrap.strategy .section-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.75);
  border-bottom: 1px solid var(--light-grey);
  margin-bottom: 15px;
  padding: 10px 0px;
  padding-top: 5px;
  text-transform: uppercase;
}
.dropdown-wrap.strategy .playbook-section,
.dropdown-wrap.strategy .chat-section {
  padding: 10px;
}
.dropdown-wrap.strategy .playbook-section .playbook-category-container {
  display: grid;
  grid-template-columns: repeat(2, 280px);
}
.dropdown-wrap.strategy .chat-section {
  background: #eff5f6;
  overflow-y: hidden;
  width: 240px;
}
.dropdown-wrap.strategy .chat-section .dropdown-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  height: 100%;
}
.dropdown-wrap.strategy .chat-section .dropdown-item .dropdown-text {
  text-align: center;
}
.dropdown-wrap.strategy .chat-section .dropdown-item:hover {
  background: none;
}
.dropdown-wrap.strategy .chat-section .dropdown-item:hover .dropdown-text div {
  color: #008c6c !important;
}
@media (max-width: 1070px) {
  .dropdown-wrap.strategy {
    display: grid;
    grid-template-columns: 580px;
    grid-gap: 10px;
    gap: 10px;
    padding: 10px !important;
  }
  .dropdown-wrap.strategy .chat-section {
    width: auto;
  }
  .dropdown-wrap.strategy .chat-section .dropdown-item {
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-gap: 15px;
    grid-gap: 15px;
    gap: 15px;
    cursor: pointer;
    align-items: flex-start;
    border-radius: 4px;
    padding: 10px;
  }
  .dropdown-wrap.strategy .chat-section .dropdown-item .dropdown-text {
    text-align: start;
  }
}
@media (max-width: 880px) {
  .fuel-header-nav {
    display: none;
  }
}

.ant-dropdown.dropdown-large .ant-dropdown-menu {
  padding: 8px 6px;
}

.ant-dropdown.dropdown-large .ant-dropdown-menu-item {
  font-size: 16px;
  padding: 8px 15px;
}

.ant-dropdown.dropdown-large .ant-dropdown-menu-item .anticon {
  font-size: 18px;
}

.article {
  width: 100%;
}

.article .ant-typography {
  overflow-wrap: anywhere;
}

.article a.ant-typography,
.article .ant-typography a {
  color: rgba(0, 0, 0, 0.85);
}

.article .article-extra a {
  color: #8ab0ab;
}

.article .show-sm {
  display: none;
}

.article .hide-sm {
  display: inline-flex;
}

.article .fuel-news-image .ant-image {
  width: 100%;
}

@media (max-width: 768px) {
  .article .show-sm {
    display: block;
  }

  .article .hide-sm {
    display: none;
  }
}

.keyword-history-wrapper {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  height: 100px;
  grid-gap: 16px;
  gap: 16px;
  position: relative;
  padding: 0 5%;
}

.vertical-grid {
  position: absolute;
  top: 0px;
  display: grid;
  grid-template-rows: repeat(4, minmax(0, 1fr));
  width: 100%;
  height: 100%;
}
.vertical-grid > div {
  border-bottom: 1px dashed #ddd;
  width: 100%;
  z-index: 0;
}
.vertical-grid > div:first-child {
  border-top: 1px dashed #ddd;
}

.history-item {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
}

.history-datum {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: var(--secondary);
  transition: background-color 0.2s;
  min-height: 4px;
  width: 100%;
  z-index: 5;
}
.history-datum:hover {
  background-color: var(--brand);
}

.history-item.pos-0 .datum-label {
  left: -16px !important;
}
.history-item.pos-1 .datum-label {
  left: -58px !important;
}
.history-item.pos-5 .datum-label {
  right: -58px !important;
}
.history-item.pos-6 .datum-label {
  right: -16px !important;
}
.history-item .datum-label {
  top: 110px;
  z-index: 1000;
  position: absolute;
  opacity: 0;
  right: -100px;
  margin-top: -5px;
  transition: opacity 0.1s, margin-top 0.1s;
}

.history-item:hover .datum-label {
  margin-top: 0px;
  opacity: 1;
}

.aside-section {
  margin-bottom: 50px;
}

.aside-section .aside-header {
  text-transform: uppercase;
  font-size: 15px;
  color: #999;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
  font-weight: 500;
  margin-bottom: 15px;
}

.authwall-card {
  padding: 35px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  grid-row-gap: 0px;
  row-gap: 0px;
  background-color: #fff;
  box-shadow: 0px 2px 4px 1px rgb(0, 0, 0, 0.15);
  max-width: 760px;
  margin: 0px auto;
}

.authwall-card img {
  height: 210px;
}

@media (max-width: 768px) {
  .authwall-card {
    padding: 25px;
    padding-top: 5px;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }
  .authwall-card img {
    height: 150px;
  }
}

.keyword-wrapper {
  padding: 25px 50px 200px;
  display: grid;
  max-width: 1400px;
  margin: auto;
}

.keyword-wrapper .keyword-header {
  margin-bottom: 30px;
}

.keyword-wrapper .keyword-body {
  display: grid;
  grid-gap: 50px;
  gap: 50px;
  grid-template-columns: minmax(0, 3fr) 1fr;
}
.article-keyword.selected {
  background-color: var(--brand) !important;
  border: none !important;
  color: white !important;
}

.sovCalender-container rect.selected {
  fill: var(--brand) !important;
}
.sovCalender-container rect {
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .keyword-wrapper {
    padding: 25px 25px 200px;
  }
  .keyword-wrapper .keyword-body {
    grid-gap: 20px;
    gap: 20px;
    grid-template-columns: 1fr;
  }
  .keyword-wrapper .keyword-body .keyword-content {
    order: 1;
  }
  .keyword-wrapper .keyword-body .keyword-aside {
    order: 0;
  }
}

.section-header .title-area {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 20px;
}

.section-header .title-area .title-extra {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

:root {
  --brand: #00b48a;
  --off-brand: #00a982;
  --accent: #0084b4;
  --yellow: #ecd67f;
  --dark-bg: #192530;
  --nav-link-color: rgba(0, 0, 0, 0.65);
  --card-bg: #fcfcfc;
  --green: #00b48a;
  --secondary: #8ab0ab;
  --accent-yellow: #ecd67f;
  --light-grey: #efebea;
  --tealish: #284256;
  --dark-blue: #192530;
  --charcoal-header: #1f2422;
  --charcoal-hero: #161a18;
  --charcoal-one: #1f2322;
  --charcoal-two: #262c2a;
  --charcoal-three: #161918;
  --charcoal-four: #262c2a;
}
html {
  line-height: 1.45;
}
body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
svg {
  vertical-align: unset;
}
.fuel-body code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.fuel-body .fuel-grid {
  display: grid;
  grid-template-columns: repeat(6, 120px);
  grid-gap: 10px;
}
.fuel-body .media {
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.fuel-body .hide-table-header thead {
  display: none;
}
.fuel-body .sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
}
.fuel-body .fuel-boring-table {
  border-spacing: 0;
  width: 100%;
  background-color: #fff;
  table-layout: fixed;
}
.fuel-body .fuel-boring-table tbody tr {
  border-top: 1px solid #eee;
}
.fuel-body .fuel-boring-table th {
  font-size: 16px;
  font-weight: 500;
}
.fuel-body .fuel-boring-table td,
.fuel-body .fuel-boring-table th {
  padding: 8px 30px;
}
.fuel-body .h1:not(.ant-list-item-meta-title),
.fuel-body h1:not(.ant-list-item-meta-title) {
  font-size: 46px;
}
.fuel-body .h2:not(.ant-list-item-meta-title),
.fuel-body h2:not(.ant-list-item-meta-title) {
  font-size: 38px;
}
.fuel-body .h3:not(.ant-list-item-meta-title),
.fuel-body h3:not(.ant-list-item-meta-title) {
  font-size: 30px;
}
.fuel-body .h4:not(.ant-list-item-meta-title),
.fuel-body h4:not(.ant-list-item-meta-title) {
  font-size: 24px;
}
.fuel-body .h5:not(.ant-list-item-meta-title),
.fuel-body h5:not(.ant-list-item-meta-title) {
  font-size: 20px;
}
.fuel-body .h6:not(.ant-list-item-meta-title),
.fuel-body h6:not(.ant-list-item-meta-title) {
  font-size: 16px;
}
.fuel-body .h1,
.fuel-body .h2,
.fuel-body .h3,
.fuel-body .h4,
.fuel-body .h5,
.fuel-body .h6,
.fuel-body h1,
.fuel-body h2,
.fuel-body h3,
.fuel-body h4,
.fuel-body h5,
.fuel-body h6 {
  line-height: 1.2;
  color: rgba(0, 0, 0, 0.85);
}
.fuel-body .article-header {
  color: #008c6c;
  font-size: 24px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 600;
}
.fuel-body .article-header.sub {
  font-size: 20px;
}
.ant-modal-root .h1,
.ant-modal-root h1 {
  font-size: 46px;
}
.ant-modal-root .h2,
.ant-modal-root h2 {
  font-size: 38px;
}
.ant-modal-root .h3,
.ant-modal-root h3 {
  font-size: 30px;
}
.ant-modal-root .h4,
.ant-modal-root h4 {
  font-size: 24px;
}
.ant-modal-root .h5,
.ant-modal-root h5 {
  font-size: 20px;
}
.ant-modal-root .h6,
.ant-modal-root h6 {
  font-size: 16px;
}
.ant-modal-root .h1,
.ant-modal-root .h2,
.ant-modal-root .h3,
.ant-modal-root .h4,
.ant-modal-root .h5,
.ant-modal-root .h6,
.ant-modal-root h1,
.ant-modal-root h2,
.ant-modal-root h3,
.ant-modal-root h4,
.ant-modal-root h5,
.ant-modal-root h6 {
  line-height: 1.2;
}
.fuel-checkable-tags {
  display: flex;
}
.fuel-checkable-tags .ant-tag {
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 100px;
  font-weight: 500;
}
.fuel-checkable-tags .ant-tag.ant-tag-checkable-checked {
  background-color: #eee;
  color: rgba(0, 0, 0, 0.85);
}
.fuel-checkable-tags .ant-tag.ant-tag-checkable:not(.ant-tag-checkable-checked) {
  color: rgba(0, 0, 0, 0.65);
}
.fuel-checkable-tags .ant-tag.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: rgba(0, 0, 0, 0.85);
}
.fuel-checkable-tags .ant-tag.ant-tag-checkable:active {
  color: rgba(0, 0, 0, 0.85);
  background-color: #ddd;
}
.fuel-checkable-tags .content-count {
  font-size: 90%;
  padding: 2px 6px;
  border-radius: 100px;
  background-color: #cdcdcd;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
}
.fuel-modal-dark .ant-modal-header {
  background-color: var(--charcoal-four);
}
.fuel-modal-dark .ant-modal-header .ant-modal-title {
  color: #fff;
  text-transform: uppercase;
}
.fuel-modal-dark .ant-modal-close {
  color: #fff;
}
.fuel-modal-dark .ant-modal-content {
  padding: 0;
}
.fuel-modal-dark .ant-modal-title {
  padding: 10px 25px;
}
.fuel-modal-dark .ant-modal-body {
  padding: 20px 25px 0;
}
.fuel-modal-dark .ant-modal-footer {
  padding: 10px 25px 20px;
}
.fuel-modal-dark .ant-modal-close {
  top: 12px;
}
.fuel-modal-dark .ant-modal-close:hover {
  color: #fff;
}
.fuel-popover-dark .ant-popover-title {
  background-color: var(--charcoal-four);
  color: #fff;
  padding: 5px 12px;
}
.fuel-popover-dark .ant-popover-arrow-content {
  --antd-arrow-background-color: var(--charcoal-four);
}
.fuel-popover-dark .ant-popover-inner-content {
  padding: 12px;
}
.ant-divider-horizontal.ant-divider-with-text {
  border-top: 1px;
  border-color: rgba(0, 0, 0, 0.06);
}
.ant-typography strong {
  font-weight: 500;
}
.fuel-title {
  color: #33444c !important;
  font-weight: 500 !important;
}
.ant-modal-mask,
.ant-drawer-mask {
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}
.ant-breadcrumb ol {
  align-items: center;
}
/* Hubspot overrides */
#hubspot-messages-iframe-container {
  z-index: 1040 !important;
}
.grid-template-2-2 {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 15px;
  gap: 15px;
}
.fs-14 {
  font-size: 14px ;
}
.fs-16 {
  font-size: 16px ;
}
.fs-18 {
  font-size: 18px ;
}
.popover_companyName {
  font-size: 16px ;
}
@media (max-width: 768px) {
  .fuel-checkable-tags {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-gap: 10px;
    gap: 10px;
  }
  .grid-template-2-2 {
    grid-template-columns: minmax(0, 1fr);
  }
}

