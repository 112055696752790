.search-result {
  display: grid;
  width: 100%;
  gap: 2px 10px;
  grid-template-areas:
    'image title'
    'image description';
  grid-template-columns: 24px 1fr;
}

.search-result > .i {
  grid-area: image;
}

.search-result > .t {
  grid-area: title;
  font-weight: 400;
  font-size: 18px;
}

.search-result > .d {
  grid-area: description;
  font-weight: 400;
  font-size: 14px;
}

.home.fuel-layout .search-bar.dark input {
  color: #eee;
}

.search-all {
  max-width: calc(100vw - 10px);
}

.search-all .loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-all .loader-option {
  position: absolute;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
}

.search-all .ant-select-item.ant-select-item-group {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  cursor: default;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin: 10px;
  padding: 0px;
}

.search-all .noresult-option {
  align-items: center;
}

div[data-id='page-header-search'] .ant-select-selector .ant-select-selection-search {
  color: white;
}
div[data-id='page-header-search'] .ant-select-selector .ant-select-selection-search input {
  font-size: 16px;
}
div[data-id='page-header-search'] .ant-select-selector .ant-select-selection-placeholder {
  color: #bfbfbf;
  font-size: 16px;
}

.advancedSearchTiles-wrapper {
  display: flex;
  justify-content: space-between;
}

.advancedSearchTiles-wrapper .filter-link {
  font-size: 16px;
}

@media (max-width: 768px) {
  .search-all-input {
    display: none !important;
  }
}
