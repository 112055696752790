.search-result {
  display: grid;
  width: 100%;
  gap: 2px;
  grid-template-areas:
    'image title'
    'image description';
  grid-template-columns: 26px 1fr;
  padding: 2px 0px;
}

.search-result > .i {
  grid-area: image;
}

.search-result > .t {
  grid-area: title;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.search-result > .d {
  grid-area: description;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  padding-left: 1px;
  color: rgba(0, 0, 0, 0.65);
}

.home.fuel-layout .search-bar.dark input {
  color: #eee;
}
