[data-tour-elem='controls'] {
  /* Attribute has this exact value */
  justify-content: center;
}

.fuel-tour {
  max-width: 360px !important;
}
.fuel-tour .link-bold {
  font-weight: 500;
}
