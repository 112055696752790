.keyword-history-wrapper {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  height: 100px;
  gap: 16px;
  position: relative;
  padding: 0 5%;
}

.vertical-grid {
  position: absolute;
  top: 0px;
  display: grid;
  grid-template-rows: repeat(4, minmax(0, 1fr));
  width: 100%;
  height: 100%;
}
.vertical-grid > div {
  border-bottom: 1px dashed #ddd;
  width: 100%;
  z-index: 0;
}
.vertical-grid > div:first-child {
  border-top: 1px dashed #ddd;
}

.history-item {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
}

.history-datum {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: var(--secondary);
  transition: background-color 0.2s;
  min-height: 4px;
  width: 100%;
  z-index: 5;
}
.history-datum:hover {
  background-color: var(--brand);
}

.history-item.pos-0 .datum-label {
  left: -16px !important;
}
.history-item.pos-1 .datum-label {
  left: -58px !important;
}
.history-item.pos-5 .datum-label {
  right: -58px !important;
}
.history-item.pos-6 .datum-label {
  right: -16px !important;
}
.history-item .datum-label {
  top: 110px;
  z-index: 1000;
  position: absolute;
  opacity: 0;
  right: -100px;
  margin-top: -5px;
  transition: opacity 0.1s, margin-top 0.1s;
}

.history-item:hover .datum-label {
  margin-top: 0px;
  opacity: 1;
}
