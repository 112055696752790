.user-dropdown {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  height: 100%;
}

.username {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 1200px) {
  .user-dropdown .username {
    display: none;
  }
}
