.company-quick svg {
  vertical-align: middle;
}
.company-quick .line-row {
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  overflow-wrap: anywhere;
  gap: 8px;
  align-items: center;
}
