.empty-state {
  min-height: 150px;
  width: 100%;
  background-color: #fcfcfc;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
}

.empty-data-wrapper {
  width: 100%;
  min-width: 200px;
  height: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.empty-img {
  width: 150px;
  height: 150px;
}
.empty-img img {
  width: 100%;
  height: 100%;
}
.empty-text {
  color: #6b6b6b;
  font-size: 18px;
  line-height: 175%;
}
