.aside-section {
  margin-bottom: 50px;
}

.aside-section .aside-header {
  text-transform: uppercase;
  font-size: 15px;
  color: #999;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  font-weight: 500;
  margin-bottom: 15px;
}
