.section-header .title-area {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 20px;
}

.section-header .title-area .title-extra {
  display: flex;
  align-items: flex-end;
  height: 100%;
}
