:root {
  --messages-sidebar: #f7fdfb;
  --messages-nav-group: #e3f7f1;
  --messages-nav-header: #00b48a;
}

.admin-messages-wrapper {
  display: grid;
  grid-template-columns: 300px minmax(0, 1fr);
  height: 100%;
}

.user-messages-content .admin-messages-wrapper {
  grid-template-columns: 225px minmax(0, 1fr);
  grid-auto-rows: 500px;
  height: 500px;
}

.admin-messages-wrapper .admin-messages-sidebar {
  overflow-y: auto;
  background-color: var(--messages-sidebar);
}
.admin-messages-wrapper .admin-messages-content {
  height: calc(100vh - 64px);
}
.user-messages-content .admin-messages-wrapper .admin-messages-content {
  height: auto;
}

.admin-messages-sidebar .messages-header {
  background-color: var(--messages-nav-header);
  padding: 15px 20px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-bottom: inset 2px rgba(0, 0, 0, 0.1);
}

.user-messages-content .messages-header {
  display: none;
}

.admin-messages-sidebar .messages-nav {
  max-height: calc(100vh - 167px);
  overflow-y: auto;
}

.admin-messages-sidebar .messages-nav .messages-nav-group .messages-nav-header {
  background-color: var(--messages-nav-group);
  color: rgba(0, 0, 0, 0.95);
  font-size: 16px;
  font-weight: 500;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.admin-messages-sidebar .messages-nav .messages-nav-group .messages-nav-items {
  padding: 10px 0px 15px;
  display: block;
}
.admin-messages-sidebar .messages-nav .messages-nav-group .messages-nav-items.hidden {
  display: none;
}
.admin-messages-sidebar .messages-nav .messages-nav-group .messages-nav-items .messages-nav-item {
  color: rgba(0, 0, 0, 0.95);
  font-size: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: 100%;
}
.admin-messages-sidebar
  .messages-nav
  .messages-nav-group
  .messages-nav-items
  .messages-nav-item
  .messages-nav-item-wrap {
  padding: 8px 20px;
  width: 100%;
}

.admin-messages-sidebar .channel-search {
  color: #fff;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.admin-messages-sidebar .channel-search::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.admin-messages-sidebar .messages-nav .messages-nav-group .messages-nav-items .messages-nav-item:before {
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  position: absolute;
  width: 0px;
  background-color: var(--messages-nav-header);
  transition: width 0.1s;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.admin-messages-sidebar .messages-nav .messages-nav-group .messages-nav-items .messages-nav-item.active:before,
.admin-messages-sidebar .messages-nav .messages-nav-group .messages-nav-items .messages-nav-item:focus:before {
  position: absolute;
  width: 5px;
}
.admin-messages-sidebar
  .messages-nav
  .messages-nav-group
  .messages-nav-items
  .messages-nav-item:hover:not(.active)
  .messages-nav-text {
  font-weight: 500;
}
.admin-messages-sidebar .messages-nav .messages-nav-group .messages-nav-items .messages-nav-item.active,
.admin-messages-sidebar .messages-nav .messages-nav-group .messages-nav-items .messages-nav-item:focus {
  background-color: #02daa72b;
}

.admin-messages-sidebar .messages-nav .messages-nav-group .messages-nav-items .messages-nav-item:active:before {
  width: 7px;
}

.admin-messages-sidebar .messages-nav .messages-nav-text {
  position: relative;
  user-select: none;
}

@keyframes ping {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.ping::before,
.ping::after {
  content: '';
  position: absolute;
  top: 40%;
  right: 20px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--accent);
}

.ping::before {
  animation: ping 1.7s ease infinite;
  opacity: 0.25;
}
