.splash {
  position: relative;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: grid;
  color: #5d5d5d;
}

.splash .ant-spin-dot-item {
  background-color: #00b48a;
}
