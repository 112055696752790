@import (css)
  url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import (css) url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap');
@import (css) url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;600;700&display=swap');

:root {
  --brand: #00b48a;
  --off-brand: #00a982;
  --accent: #0084b4;
  --yellow: #ecd67f;
  // --dark-bg: rgba(0, 0, 0, 0.95);
  --dark-bg: #192530;
  --nav-link-color: rgba(0, 0, 0, 0.65);
  --card-bg: #fcfcfc;

  --green: #00b48a;
  --secondary: #8ab0ab;
  --accent-yellow: #ecd67f;
  --light-grey: #efebea;
  --tealish: #284256;
  --dark-blue: #192530;

  --charcoal-header: #1f2422;
  --charcoal-hero: #161a18;
  --charcoal-one: #1f2322;
  --charcoal-two: rgb(38, 44, 42);
  --charcoal-three: #161918;
  --charcoal-four: #262c2a;
}

html {
  line-height: 1.45;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

svg {
  vertical-align: unset;
}

.fuel-body {
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  .fuel-grid {
    display: grid;
    grid-template-columns: repeat(6, 120px);
    grid-gap: 10px;
  }

  .media {
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
  }

  .hide-table-header thead {
    display: none;
  }

  .sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
  }

  .fuel-boring-table {
    border-spacing: 0;
    width: 100%;
    background-color: #fff;
    table-layout: fixed;
  }

  .fuel-boring-table tbody tr {
    border-top: 1px solid #eee;
  }
  .fuel-boring-table th {
    font-size: 16px;
    font-weight: 500;
  }

  .fuel-boring-table td,
  .fuel-boring-table th {
    padding: 8px 30px;
  }

  .h1:not(.ant-list-item-meta-title),
  h1:not(.ant-list-item-meta-title) {
    font-size: 46px;
  }

  .h2:not(.ant-list-item-meta-title),
  h2:not(.ant-list-item-meta-title) {
    font-size: 38px;
  }

  .h3:not(.ant-list-item-meta-title),
  h3:not(.ant-list-item-meta-title) {
    font-size: 30px;
  }

  .h4:not(.ant-list-item-meta-title),
  h4:not(.ant-list-item-meta-title) {
    font-size: 24px;
  }

  .h5:not(.ant-list-item-meta-title),
  h5:not(.ant-list-item-meta-title) {
    font-size: 20px;
  }

  .h6:not(.ant-list-item-meta-title),
  h6:not(.ant-list-item-meta-title) {
    font-size: 16px;
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2;
    color: rgba(0, 0, 0, 0.85);
  }

  .article-header {
    color: rgb(0, 140, 108);
    font-size: 24px;
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-weight: 600;
  }
  .article-header.sub {
    font-size: 20px;
  }
}

.ant-modal-root {
  .h1,
  h1 {
    font-size: 46px;
  }

  .h2,
  h2 {
    font-size: 38px;
  }

  .h3,
  h3 {
    font-size: 30px;
  }

  .h4,
  h4 {
    font-size: 24px;
  }

  .h5,
  h5 {
    font-size: 20px;
  }

  .h6,
  h6 {
    font-size: 16px;
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2;
  }
}

.fuel-checkable-tags {
  display: flex;
}

.fuel-checkable-tags .ant-tag {
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 100px;
  font-weight: 500;
}

.fuel-checkable-tags .ant-tag.ant-tag-checkable-checked {
  background-color: #eee;
  color: rgba(0, 0, 0, 0.85);
}

.fuel-checkable-tags .ant-tag.ant-tag-checkable:not(.ant-tag-checkable-checked) {
  color: rgba(0, 0, 0, 0.65);
}

.fuel-checkable-tags .ant-tag.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: rgba(0, 0, 0, 0.85);
}

.fuel-checkable-tags .ant-tag.ant-tag-checkable:active {
  color: rgba(0, 0, 0, 0.85);
  background-color: #ddd;
}

.fuel-checkable-tags .content-count {
  font-size: 90%;
  padding: 2px 6px;
  border-radius: 100px;
  background-color: #cdcdcd;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
}

.fuel-modal-dark .ant-modal-header {
  background-color: var(--charcoal-four);
}
.fuel-modal-dark .ant-modal-header .ant-modal-title {
  color: #fff;
  text-transform: uppercase;
}
.fuel-modal-dark .ant-modal-close {
  color: #fff;
}
.fuel-modal-dark .ant-modal-content {
  padding: 0;
}
.fuel-modal-dark .ant-modal-title {
  padding: 10px 25px;
}
.fuel-modal-dark .ant-modal-body {
  padding: 20px 25px 0;
}
.fuel-modal-dark .ant-modal-footer {
  padding: 10px 25px 20px;
}
.fuel-modal-dark .ant-modal-close {
  top: 12px;
}
.fuel-modal-dark .ant-modal-close:hover {
  color: #fff;
}

.fuel-popover-dark .ant-popover-title {
  background-color: var(--charcoal-four);
  color: #fff;
  padding: 5px 12px;
}

.fuel-popover-dark .ant-popover-arrow-content {
  --antd-arrow-background-color: var(--charcoal-four);
}
.fuel-popover-dark .ant-popover-inner-content {
  padding: 12px;
}

.ant-divider-horizontal.ant-divider-with-text {
  border-top: 1px;
  border-color: rgba(0, 0, 0, 0.06);
}

.ant-typography strong {
  font-weight: 500;
}

.fuel-title {
  color: #33444c !important;
  font-weight: 500 !important;
}

.ant-modal-mask,
.ant-drawer-mask {
  backdrop-filter: blur(2px);
}

.ant-breadcrumb ol {
  align-items: center;
}
/* Hubspot overrides */
#hubspot-messages-iframe-container {
  z-index: 1040 !important;
}

.grid-template-2-2 {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 15px;
}


.fs-14{
  font-size: 14px ;
}
.fs-16{
  font-size: 16px ;
}
.fs-18{
  font-size: 18px ;
}
.popover_companyName {
  // apply to companyName where it is wrapped by <AuthenticatedCompanyPopover/>
  font-size: 16px ;
}
@media (max-width: 768px) {
  .fuel-checkable-tags {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
  }
  .grid-template-2-2 {
    grid-template-columns: minmax(0, 1fr);
  }
}
