.google-btn {
  font-family: 'Roboto';
  font-weight: 500 !important;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.54) !important;
}

.google-btn:hover {
  border-color: #d9d9d9 !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.google-btn:active {
  border-color: #d9d9d9 !important;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.google-btn:focus {
  border-color: #d9d9d9 !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
