.home.fuel-layout {
  display: grid;
  grid-template-rows: 64px 1fr auto;
  min-height: 100vh;
  background-color: #fff;

  .card-bg {
    background-color: #fcfcfc;
    border-radius: 4px;
    padding: 30px;
  }

  //common classes for fuelTools
  .d-flex {
    display: flex;
    gap: 20px;
    justify-content: center;
  }
  .d-flex-vertical {
    flex-direction: column;
  }
  .d-flex-horizontal {
    flex-direction: row;
  }

  /* PAGE HEADER */
  .ant-page-header.has-breadcrumb {
    padding-top: 0px;
  }
  .ant-page-header {
    padding: 16px 0px;
  }

  .ant-breadcrumb > span:last-child {
    color: var(--brand);
    font-weight: 500;
  }
  .ant-table.ant-table-middle .ant-table-tbody > tr > td.cell-avatars {
    padding: 7px 8px 0px;
  }

  .ant-list-bordered {
    border: 1px solid #f0f0f0;
  }

  .tilt {
    position: relative;
  }
  .tilt:after {
    background: inherit;
    bottom: 0;
    content: '';
    display: block;
    height: 20%;
    left: 0;
    position: absolute;
    right: 0;
    transform: skewY(1.5deg);
    transform-origin: 0 100%;
    z-index: -1;
  }

  .tilt.flip {
    transform: skewY(-1.5deg);
    transform-origin: 100%;
  }

  .layout-padding {
    padding: 25px 50px;
  }
  .layout-padding-none {
    padding: 0px 50px;
  }
  .layout-max-width {
    max-width: 1400px;
    margin: auto;
  }

  .fuel-sider {
    display: none;
  }

  .trigger {
    display: none;
    color: #fff;
    align-items: center;
    height: 100%;
  }

  .fuel-header,
  .ant-menu.ant-menu-dark {
    background-color: var(--charcoal-header);
  }

  .fuel-header .header-wrapper {
    height: 100%;
    display: grid;
    grid-template-columns: 210px 3fr 1fr auto;
    grid-template-rows: 64px;
    gap: 20px;
  }

  .fuel-header .full-width-search {
    height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 36px 1fr;
    gap: 20px;
  }

  .search-btn {
    display: none;
  }

  .fuel-content {
    background-color: #fff;
    padding-bottom: 100px;
  }

  /*NOTEBOOKS*/
  h1.ant-typography,
  h2.ant-typography,
  h3.ant-typography,
  h4.ant-typography,
  h5.ant-typography,
  h6.ant-typography {
    font-weight: 500;
  }

  .fuel-page-header .ant-page-header-heading-title {
    font-weight: 400;
    font-size: 26px;
    white-space: initial;
  }

  .fuel-page {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'bc bc bc bc bc bc bc bc bc'
      'in in co co co co co co ex';
    gap: 30px;
  }
  .fuel-page .page-grid {
    min-width: 0;
  }
  .fuel-page .page-breadcrumb {
    grid-area: bc;
  }
  .fuel-page .page-info {
    grid-area: in;
  }
  .fuel-page .page-content {
    grid-area: co;
  }
  .fuel-page .page-extra {
    grid-area: ex;
  }

  .fuel-page .header-shadow {
    position: absolute;
    height: 114px;
    width: 100%;
    left: 0;
    border-bottom: 1px solid #efefef;
    margin-top: -25px;
  }

  .fuel-page .ant-typography-edit-content {
    left: 0px;
  }

  .fuel-menu.ant-menu-dark .ant-menu-item,
  .fuel-menu.ant-menu-dark .ant-menu-item a,
  .fuel-menu.ant-menu-dark .ant-menu-submenu,
  .fuel-menu.ant-menu-dark .ant-menu-submenu a,
  .fuel-menu.ant-menu-dark .ant-menu-submenu .ant-menu-item,
  .fuel-menu.ant-menu-dark .ant-menu-submenu .ant-menu-item a {
    color: rgba(255, 255, 255, 0.85);
    font-weight: 600;
    font-size: 13px;
    display: flex;
  }
  .fuel-menu.ant-menu-dark .ant-menu-item-selected,
  .fuel-menu.ant-menu-dark .ant-menu-item:hover,
  .fuel-menu.ant-menu-dark .ant-menu-item:hover a {
    background-color: transparent;
    color: #fff;
  }

  /* RESPONSIVE */
  @media (max-width: 1200px) {
    .fuel-page {
      grid-template-columns: repeat(9, 1fr);
      grid-template-rows: auto 1fr;
      grid-template-areas:
        'bc bc bc bc bc bc bc bc bc'
        'co co co co co co co ex ex';
    }
    .fuel-page .page-info {
      display: none;
    }

    .fuel-header .header-wrapper {
      grid-template-columns: 56px 1fr 1fr 120px;
    }

    .fuel-header .header-wrapper .fuel-logo {
      object-fit: cover;
      object-position: 0 0;
      width: 55px;
    }
    .mobile-hide-w1200 {
      display: none;
    }
  }

  /* RESPONSIVE */
  @media (max-width: 880px) {
    .fuel-header {
      padding: 0px 20px;
    }
    .fuel-header .header-wrapper {
      grid-template-columns: 56px 200px 1fr auto;
      gap: 10px;
    }
    .search-all {
      display: none;
    }

    .fuel-menu {
      display: none;
    }
    .trigger {
      display: flex;
    }
    .fuel-sider {
      display: block;
    }
  }

  @media (max-width: 992px) {
    .mobile-hide-w992 {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .layout-padding {
      padding: 25px;
    }
    .layout-padding-none {
      /*need padding for mobile*/
      padding: 25px;
    }

    .fuel-header .header-wrapper {
      grid-template-columns: 56px 1fr auto auto;
      gap: 10px;
    }

    .fuel-page {
      grid-template-columns: repeat(9, 1fr);
      grid-template-rows: auto 1fr;
      grid-template-areas:
        'bc bc bc bc bc bc bc bc bc'
        'co co co co co co co co co';
    }
    .fuel-page .page-info {
      display: none;
    }
    .fuel-page .page-extra {
      display: none;
    }
    .search-btn {
      display: block;
    }
    .mobile-hide-w768 {
      display: none;
    }
  }
  @media (max-width: 576px) {
    .mobile-hide-w576 {
      display: none;
    }
  }
}

.home.fuel-layout.no-footer {
  grid-template-rows: 64px 1fr;
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: var(--charcoal-two) !important;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item a {
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
  font-size: 16px;
}

.ant-popover.remove-padding .ant-popover-inner-content {
  padding: 0px;
}

@media print {
  .fuel-page {
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: 'co co co co co co co co co';
  }
  .fuel-page .page-breadcrumb .ant-breadcrumb,
  .fuel-page .page-breadcrumb .ant-page-header-heading-extra,
  .fuel-page .page-breadcrumb .header-shadow {
    display: none;
  }
  .fuel-page .page-info {
    display: none;
  }
  .fuel-page .page-extra {
    display: none;
  }
}

.fuel-card {
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  padding: 30px;
}

/** DEPRECATED, working to remove **/
.fuel-body {
  .layout2 {
    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    #root {
      height: 100%;
    }

    .ant-table.ant-table-middle .ant-table-tbody > tr > td.cell-avatars {
      padding: 7px 8px 0px;
    }

    /* ACCENT BUTTON */
    .ant-btn-accent {
      background: var(--yellow);
      border-color: var(--yellow);
    }

    .ant-layout-header {
      background: #fff;
    }

    /* SIDER */
    .fuel-sider.ant-layout-sider {
      background: var(--charcoal-header);
      transition: none;
    }
    .fuel-sider.ant-layout-sider .ant-menu {
      background: transparent;
      color: #ccc;
      font-weight: 400;
      font-size: 16px;
    }

    .fuel-sider.ant-layout-sider .ant-menu > .ant-menu-item-divider {
      background-color: rgba(255, 255, 255, 0.15);
      margin: 15px 0px;
    }

    .fuel-sider.ant-layout-sider .ant-menu .ant-menu-item.ant-menu-item-selected {
      background: var(--brand);
      color: #fff;
      border-radius: 3px;
    }
    .fuel-sider.ant-layout-sider.ant-layout-sider-collapsed .ant-menu .ant-menu-item.ant-menu-item-selected {
      border-radius: 0px;
    }
    .fuel-sider .ant-menu-item a,
    .fuel-sider .ant-menu-submenu a {
      color: #ccc;
    }
    .fuel-sider .ant-menu-item a:hover,
    .fuel-sider .ant-menu-submenu a:hover {
      color: var(--brand);
    }
    .fuel-sider.ant-layout-sider .ant-menu .ant-menu-item-selected a {
      color: #fff;
    }
    .fuel-sider.ant-layout-sider .ant-menu .ant-menu-item-selected a:hover {
      color: #fff;
    }

    .fuel-sider.ant-layout-sider .ant-menu ul,
    .fuel-sider.ant-layout-sider .ant-menu ol {
      padding: 0px 10px;
    }

    .fuel-sider.ant-layout-sider.ant-layout-sider-collapsed .ant-menu ul,
    .fuel-sider.ant-layout-sider.ant-layout-sider-collapsed .ant-menu ol {
      padding: 0px;
    }

    .fuel-sider.ant-layout-sider .ant-menu-item-group-title {
      color: rgba(255, 255, 255, 0.45);
    }

    .fuel-sider.ant-layout-sider.ant-layout-sider-collapsed .ant-menu-item-group-title {
      color: transparent;
    }

    .fuel-sider.ant-layout-sider.ant-layout-sider-collapsed .hide-collapsed {
      display: none;
    }

    .fuel-sider.ant-layout-sider .ant-menu-inline .ant-menu-item,
    .ant-menu-inline .ant-menu-submenu-title {
      width: 100%;
    }

    .fuel-sider.ant-layout-sider .ant-menu .ant-menu-item:not(.ant-menu-item-selected):hover,
    .fuel-sider.ant-layout-sider .ant-menu-item-active,
    .fuel-sider.ant-layout-sider .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .fuel-sider.ant-layout-sider .ant-menu-submenu-active,
    .fuel-sider.ant-layout-sider .ant-menu-submenu-title:hover {
      color: var(--brand);
    }

    .fuel-sider.ant-layout-sider .ant-menu-vertical .ant-menu-item::after,
    .fuel-sider.ant-layout-sider .ant-menu-vertical-left .ant-menu-item::after,
    .fuel-sider.ant-layout-sider .ant-menu-vertical-right .ant-menu-item::after,
    .fuel-sider.ant-layout-sider .ant-menu-inline .ant-menu-item::after {
      left: 0px;
      right: auto;
      border-right: none;
    }

    .fuel-sider.ant-layout-sider .ant-menu-inline,
    .fuel-sider.ant-layout-sider .ant-menu-vertical,
    .fuel-sider.ant-layout-sider .ant-menu-vertical-left {
      border-right: none;
    }

    .fuel-sider .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
    .fuel-sider .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
      position: absolute;
      width: 6px;
      height: 1.5px;
      background-image: linear-gradient(to right, rgba(204, 204, 204, 1), rgba(204, 204, 204, 1));
      border-radius: 2px;
      transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      content: '';
    }

    .fuel-sider
      .ant-menu-submenu-inline.ant-menu-submenu-selected
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::before,
    .fuel-sider
      .ant-menu-submenu-inline.ant-menu-submenu-selected
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::after {
      background-image: linear-gradient(to right, var(--brand), var(--brand));
    }

    /* TODO: ORGANIZE */

    .recently-viewed .ant-menu-item-group-list .ant-menu-item {
      align-items: center;
      display: flex;
      height: 32px;
    }

    .recently-viewed .ant-menu-item-group-list .ant-menu-item img {
      margin-right: 8px;
    }

    .search .ant-input-prefix {
      margin-right: 12px;
    }

    .ant-table-thead > tr > th {
      font-weight: 400;
      font-size: 12px;
      text-transform: uppercase;
    }

    .ant-btn-text {
      color: rgba(0, 0, 0, 0.65);
      background: 0 0;
      border-color: transparent;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .ant-btn-text:hover,
    .ant-btn-text:focus {
      color: rgba(0, 0, 0, 0.65);
      background: rgba(0, 0, 0, 0.018);
      border-color: transparent;
    }

    .ant-drawer-body {
      padding: 0px;
      padding-right: 1px;
    }

    /*NOTEBOOKS*/
    h1.ant-typography,
    h2.ant-typography,
    h3.ant-typography,
    h4.ant-typography,
    h5.ant-typography,
    h6.ant-typography {
      font-weight: 400;
    }

    #notebook-content,
    .limit-width {
      max-width: 1080px;
      margin: 30px auto;
      min-height: 100%;
      display: grid;
      grid-template-rows: min-content;
      word-break: break-word;
    }
    #notebook-content {
      font-size: 16px;
    }

    .card-bg {
      background-color: var(--card-bg);
      padding: 30px;
    }

    /* RESPONSIVE */
    @media (min-width: 768px) {
      .fuel-header {
        padding: 0 50px;
      }
    }
  }
}

.recently-viewed .ant-menu-item-group-list .ant-menu-item img {
  margin-right: 8px;
}

/*other drawers*/
.ant-drawer-content-wrapper {
  max-width: 100%;
}

/* DRAWER */
.fuel-sider .ant-drawer-header {
  background: var(--charcoal-header);
  transition: none;
  padding: 0px;
  border-bottom: none;
  border-radius: 0px;
}
.fuel-sider .ant-drawer-header .logo {
  width: 100%;
  height: 92px;
  padding: 0px 18px;
  float: left;
  display: flex;
}
.fuel-sider .ant-drawer-header .logo div {
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
}

.fuel-sider .ant-drawer-body {
  background: var(--charcoal-header);
  transition: none;
  padding: 0px;
}
.fuel-sider .ant-menu {
  background: transparent;
  color: #ccc;
  font-weight: 400;
  font-size: 16px;
}

.fuel-sider .ant-menu > .ant-menu-item-divider {
  padding: 0px 10px;
  background-color: rgba(255, 255, 255, 0.45);
}

.fuel-sider .ant-menu .ant-menu-item {
  border-radius: 3px;
}

.fuel-sider .ant-menu .ant-menu-item.ant-menu-item-selected {
  background: var(--brand);
  color: #fff;
  border-radius: 3px;
}

.recent-activity .ant-menu-item-group-list .ant-menu-item img {
  margin-right: 8px;
  border-radius: 4px;
}

.recent-activity .ant-menu-item-group-list .ant-menu-item a {
  align-items: center;
  display: flex;
}

.fuel-sider .ant-menu-item a,
.fuel-sider .ant-menu-submenu a {
  color: #ccc;
}
.fuel-sider .ant-menu-item a:hover,
.fuel-sider .ant-menu-submenu a:hover {
  color: var(--brand);
}
.fuel-sider .ant-menu .ant-menu-item-selected a {
  color: #fff;
}
.fuel-sider .ant-menu .ant-menu-item-selected a:hover {
  color: #fff;
}

.fuel-sider .ant-menu ul,
.fuel-sider .ant-menu ol {
  padding: 0px 10px;
}

.fuel-sider .ant-menu-item-group-title {
  color: rgba(255, 255, 255, 0.45);
}

.fuel-sider .ant-menu-inline .ant-menu-item {
  width: 100%;
}

.fuel-sider .ant-menu .ant-menu-item:not(.ant-menu-item-selected):hover,
.fuel-sider .ant-menu-item-active,
.fuel-sider .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.fuel-sider .ant-menu-submenu-active,
.fuel-sider .ant-menu-submenu-title:hover {
  color: var(--brand);
}

.fuel-sider .ant-menu-vertical .ant-menu-item::after,
.fuel-sider .ant-menu-vertical-left .ant-menu-item::after,
.fuel-sider .ant-menu-vertical-right .ant-menu-item::after,
.fuel-sider .ant-menu-inline .ant-menu-item::after {
  left: 0px;
  right: auto;
  border-right: none;
}

.fuel-sider .ant-menu-inline,
.fuel-sider .ant-menu-vertical,
.fuel-sider .ant-menu-vertical-left {
  border-right: none;
}
.fuel-sider .ant-tag {
  margin-bottom: 8px;
}

.fuel-sider .recent-activity .ant-menu-item {
  padding-left: 8px !important;
  margin: 0px;
}
.fuel-sider.ant-layout-sider .ant-menu .recent-activity .ant-menu-item.ant-menu-item-selected {
  background: transparent;
  color: #ccc;
}

.fuel-sider .ant-menu-submenu > .ant-menu {
  background-color: transparent;
}

.fuel-sider .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.fuel-sider .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-image: linear-gradient(to right, rgba(204, 204, 204, 1), rgba(204, 204, 204, 1));
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}

.fuel-sider .anticon {
  font-size: 18px !important;
}

.fuel-sider:not(.ant-layout-sider-collapsed) .home-nav {
  width: 92% !important;
  margin: 8px auto;
  padding-left: 14px !important;
}

/* UTILITY */
.ant-modal-root .text-muted,
.text-muted {
  color: #6c757d !important;
  margin-bottom: 5px !important;
}

.ant-modal-root .mute-title,
.mute-title {
  color: rgba(0, 0, 0, 0.85);
}

.ant-modal-root .weight-400,
.weight-400 {
  font-weight: 400;
}

.ant-modal-root h1.ant-typography,
.ant-modal-root h2.ant-typography,
.ant-modal-root h3.ant-typography,
.ant-modal-root h4.ant-typography,
.ant-modal-root h5.ant-typography,
.ant-modal-root h6.ant-typography {
  font-weight: 400;
}

.no-margin,
.ant-modal-root .no-margin {
  margin: 0px;
}

//fuel-navigation
.fuel-header-nav .nav-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.fuel-header-nav .nav-list .nav-item {
  position: relative;
  padding: 0 16px;
}

.fuel-header-nav .nav-list .nav-item > a,
.fuel-header-nav .nav-list .nav-item > span {
  line-height: 64px;
}

.fuel-header-nav .nav-list .nav-item > a,
.fuel-header-nav .nav-list .nav-item > span {
  display: block;
  font-weight: 600;
  font-size: 15px;
  color: hsla(0, 0%, 100%, 0.85);
  text-decoration: none;
}

.fuel-header-nav .nav-list .dropdown,
.fuel-header-nav .nav-list .nested-dropdown {
  position: absolute;
  top: 110%;
  z-index: 1000000;
  background-color: transparent;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease 0s, visibility 0s linear 0s, top 0.2s ease 0s;
  min-width: 180px;
}

.fuel-header-nav .nav-list .nested-dropdown {
  top: 10px;
  left: 100%;
  transition: none;
}
.fuel-header-nav .nav-list .dropdown .dropdown-wrap,
.fuel-header-nav .nav-list .nested-dropdown .nested-dropdown-wrap {
  position: relative;
  padding: 10px;
  line-height: 20px;
  background-color: #fff;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-top: -10px;
}
.fuel-header-nav .nav-list .dropdown .dropdown-pointer {
  position: absolute;
  left: 24px;
  top: -8px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top: 0;
  border-bottom: 8px solid #fff;
}
.fuel-header-nav .nav-list .dropdown .dropdown-item.has-children {
  position: relative;
}

.fuel-header-nav .nav-list .dropdown .dropdown-item,
.fuel-header-nav .nav-list .dropdown .dropdown-item .nested-dropdown-item {
  display: grid;
  grid-template-columns: 32px 1fr;
  gap: 15px;
  cursor: pointer;
  align-items: flex-start;
  border-radius: 4px;
  padding: 10px;
}
.fuel-header-nav .nav-list .dropdown .dropdown-item:last-child {
  margin-bottom: 0;
}

.fuel-header-nav .nav-list .dropdown .dropdown-item:hover,
.fuel-header-nav .nav-list .nested-dropdown .nested-dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.fuel-header-nav .nav-list .dropdown .dropdown-item .dropdown-text div,
.fuel-header-nav .nav-list .nested-dropdown .nested-dropdown-item {
  margin: 0;
  margin-bottom: 3px;
  line-height: 20px;
  font-size: 16px;
  color: #333 !important;
  font-weight: 500;
}
.fuel-header-nav .nav-list .dropdown .dropdown-item .dropdown-icon {
  padding: 5px;
  border-radius: 5px;
  color: #008c6c;
  font-size: 18px;
  position: relative;
  text-align: center;
  border: 1px solid var(--messages-nav-group);
}

.fuel-header-nav .nav-list .dropdown.advisory-key .dropdown-item .dropdown-icon {
  color: #00668c;
}

.fuel-header-nav .nav-list .dropdown .dropdown-item .dropdown-icon .blob,
.fuel-header-nav .nav-list .dropdown .dropdown-item .dropdown-icon .blob::after,
.fuel-header-nav .nav-list .dropdown .dropdown-item .dropdown-icon .blob::before {
  width: 50px;
  height: 50px;
  border-radius: 25% 75% 22% 78% ~'/' 62% 50% 50% 38%;
}

.fuel-header-nav .nav-list .dropdown.advisory-key .dropdown-item .dropdown-icon .blob,
.fuel-header-nav .nav-list .dropdown.advisory-key .dropdown-item .dropdown-icon .blob::after,
.fuel-header-nav .nav-list .dropdown.advisory-key .dropdown-item .dropdown-icon .blob::before {
  width: 50px;
  height: 50px;
  border-radius: 70% 30% 30% 70% ~'/' 60% 40% 60% 40%;
}

.fuel-header-nav .nav-list .dropdown .dropdown-item .dropdown-icon .blob {
  position: absolute;
  background-color: rgba(210, 230, 224, 1);
  isolation: isolate;
  display: none;
}

.fuel-header-nav .nav-list .dropdown.advisory-key .dropdown-item .dropdown-icon .blob {
  background-color: #e5eff3;
}

.fuel-header-nav .nav-list .dropdown .dropdown-item .dropdown-icon .blob::before {
  top: 0%;
  left: -25%;
}

.fuel-header-nav .nav-list .dropdown .dropdown-item .dropdown-icon .blob::after {
  top: 25%;
  left: -25%;
}

.fuel-header-nav .nav-list .dropdown .dropdown-item .dropdown-text p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.fuel-header-nav .nav-list .nav-item > a:hover + .dropdown,
.fuel-header-nav .nav-list .nav-item > span:hover + .dropdown,
.fuel-header-nav .nav-list .dropdown:hover {
  opacity: 1;
  visibility: visible;
  top: 100%;
  transition-delay: 0s, 0s, 0s;
}
.fuel-header-nav .nav-list .dropdown-item:hover > .nested-dropdown,
.fuel-header-nav .nav-list .dropdown-item:hover > .nested-dropdown,
.fuel-header-nav .nav-list .nested-dropdown:hover {
  opacity: 1;
  visibility: visible;
}

.fuel-header-nav .nav-list .dropdown.dir_left {
  right: 0;
}
.fuel-header-nav .nav-list .dropdown.dir_right {
  left: 0;
}

// strategy dropdown
.dropdown-wrap.strategy {
  display: grid;
  grid-template-columns: 580px auto;
  gap: 10px;
  padding: 0 !important;
  .section-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.75);
    border-bottom: 1px solid var(--light-grey);
    margin-bottom: 15px;
    padding: 10px 0px;
    padding-top: 5px;
    text-transform: uppercase;
  }
  .playbook-section,
  .chat-section {
    padding: 10px;
  }
  .playbook-section .playbook-category-container {
    display: grid;
    grid-template-columns: repeat(2, 280px);
  }
  .chat-section {
    background: #eff5f6;
    overflow-y: hidden;
    width: 240px;
    .dropdown-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      height: 100%;
      .dropdown-text {
        text-align: center;
      }
    }
    .dropdown-item:hover {
      background: none;
      .dropdown-text div {
        color: #008c6c !important;
      }
    }
  }
}

@media (max-width: 1070px) {
  .dropdown-wrap.strategy {
    display: grid;
    grid-template-columns: 580px;
    gap: 10px;
    padding: 10px !important;
    .chat-section {
      width: auto;
      .dropdown-item {
        display: grid;
        grid-template-columns: 50px 1fr;
        grid-gap: 15px;
        gap: 15px;
        cursor: pointer;
        align-items: flex-start;
        border-radius: 4px;
        padding: 10px;
        .dropdown-text {
          text-align: start;
        }
      }
    }
  }
}

@media (max-width: 880px) {
  .fuel-header-nav {
    display: none;
  }
}
