.keyword-wrapper {
  padding: 25px 50px 200px;
  display: grid;
  max-width: 1400px;
  margin: auto;
}

.keyword-wrapper .keyword-header {
  margin-bottom: 30px;
}

.keyword-wrapper .keyword-body {
  display: grid;
  gap: 50px;
  grid-template-columns: minmax(0, 3fr) 1fr;
}
.article-keyword.selected {
  background-color: var(--brand) !important;
  border: none !important;
  color: white !important;
}

.sovCalender-container rect.selected {
  fill: var(--brand) !important;
}
.sovCalender-container rect {
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .keyword-wrapper {
    padding: 25px 25px 200px;
  }
  .keyword-wrapper .keyword-body {
    gap: 20px;
    grid-template-columns: 1fr;
  }
  .keyword-wrapper .keyword-body .keyword-content {
    order: 1;
  }
  .keyword-wrapper .keyword-body .keyword-aside {
    order: 0;
  }
}
