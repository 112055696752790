.stat-section .stat-header {
  text-transform: uppercase;
  font-size: 15px;
  color: #aaa;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  font-weight: 500;
  margin-bottom: 8px;
  justify-content: space-between;
}
.stat-section .stat-header a {
  color: #aaa;
  transition: color 0.2s;
}

.stat-section .stat-header a:hover {
  color: #888;
}

.grid-stats {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 0;
}
.grid-stats .grid-stat {
  border-top: 1px solid #eee;
  padding: 15px;
}
.grid-stats .grid-stat:nth-child(odd) {
  border-right: 1px solid #eee;
}

.grid-stats .grid-stat .grid-stat-value {
  font-size: 20px;
}
