section.fade {
  opacity: 0;
  transition: opacity 800ms ease-out, transform 600ms ease-out;
  will-change: opacity, visibility;
}

section.fade.outro {
  transition: opacity 200ms ease-out, transform 300ms ease-out;
}

/* animate vertically */
section.fade.transform-y-0 {
  transform: translateY(0);
}
section.fade.transform-y-10 {
  transform: translateY(10%);
}
section.fade.transform-y-100 {
  transform: translateY(100%);
}

/* animate horizontally */
section.fade.transform-x-0 {
  transform: translateX(100%);
}
section.fade.transform-x-2 {
  transform: translateX(2%);
}
section.fade.transform-x-10 {
  transform: translateX(10%);
}
section.fade.transform-x-100 {
  transform: translateX(100%);
}

/* delay animation */

section.fade.delay-01 {
  transition-delay: 100ms;
}
section.fade.delay-02 {
  transition-delay: 200ms;
}
section.fade.delay-03 {
  transition-delay: 300ms;
}
section.fade.delay-04 {
  transition-delay: 400ms;
}
section.fade.delay-05 {
  transition-delay: 500ms;
}
section.fade.delay-06 {
  transition-delay: 600ms;
}
section.fade.delay-07 {
  transition-delay: 700ms;
}
section.fade.delay-08 {
  transition-delay: 800ms;
}

section.fade.delay-1 {
  transition-delay: 250ms;
}
section.fade.delay-2 {
  transition-delay: 500ms;
}
section.fade.delay-3 {
  transition-delay: 750ms;
}
section.fade.delay-4 {
  transition-delay: 1000ms;
}
section.fade.delay-5 {
  transition-delay: 1250ms;
}
section.fade.delay-6 {
  transition-delay: 1500ms;
}
section.fade.delay-7 {
  transition-delay: 1750ms;
}
section.fade.delay-8 {
  transition-delay: 2000ms;
}

section.fade.outro {
  transition-delay: 0ms !important;
}

/* just fade in */
section.fade.plain {
  transform: none;
}

section.fade.is-visible {
  opacity: 1;
  transform: none;
}
