.ant-form-item-label > label,
.ant-form-item-label > label > .ant-row {
  width: 100%;
}

.authenticontainer {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 1fr;
}

.authenticontainer .marketing {
  max-width: 100%;
  background-color: var(--charcoal-hero);
  display: grid;
  grid-template-rows: 152px 1fr;
  justify-content: end;
  padding: 50px;
}
.authenticontainer .marketing .marketing-message {
  padding-top: 2em;
}

.authenticontainer .user-form {
  width: 100%;
  display: grid;
  grid-template-rows: 152px 1fr 22px;
  padding: 50px;
}

.authenticontainer .user-form .user-content {
  padding: 2em 0px 4em;
}

@media (max-width: 768px) {
  .authenticontainer {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  .authenticontainer .marketing {
    display: none;
  }
  .authenticontainer .user-form {
    padding: 25px;
  }
  .authenticontainer .user-form .user-content {
    padding: 0px 0px 4em;
  }
  .authenticontainer .user-form .marketing-logo {
    margin: 0px auto;
  }
  .authenticontainer .user-form .auth-footer {
    margin: 0px auto;
  }
}
