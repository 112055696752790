.company-gridview .company-summary {
  padding: 15px;
  background-color: #fff;
  gap: 10px;
  align-items: center;
}

.company-summary .company-bullets {
  display: grid;
  grid-template-columns: 32px minmax(0, 1fr);
  gap: 15px;
  align-items: start;
  margin-bottom: 15px;
}

.company-summary .company-items {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.company-summary .company-items .company-item {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}
.w-100 {
  width: 100%;
}
