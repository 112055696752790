.list-tags {
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
}

.list-tags .list-tag {
  padding: 2px 6px 2px 12px;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  color: rgba(0, 0, 0, 0.85) !important;
  font-weight: 600;
  font-family: 'Nunito';
  border-radius: 100px;
  word-wrap: normal;
  display: inline-block;
  min-width: 48px;
  text-align: center;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.list-tags .list-tag .list-tag-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-tags .list-tag .list-tag-text:hover {
  color: #008c6c;
  cursor: pointer;
}

.list-tags .list-tag .remove-list {
  font-size: 10px;
  display: flex;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
}

.list-tags-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.list-tag-new {
  padding: 2px 12px;
  background-color: #fff;
  border: 1px dashed #e4e4e4;
  color: rgba(0, 0, 0, 0.65) !important;
  font-weight: 500;
  border-radius: 100px;
  word-wrap: normal;
  width: 100px;
  text-align: center;
  font-size: 14px;
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.1s, width 0.3s;
  overflow: hidden;
}

.list-tag-new.centered {
  margin: auto;
}

.list-tag-new.active {
  width: 100%;
  text-transform: initial;
  cursor: default;
  justify-content: start;
}

.list-tags .list-tag-new:hover {
  background-color: #fafafa;
}
.list-tags .list-tag-new:active {
  background-color: #fefefe;
}
